import { supabase } from '@shared/supabase';
import { MutationProvider, SupabaseError } from '@shared/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { removeDocument } from './remove-document';

export const useRemoveDocumentMutation: MutationProvider<
  { status: string },
  number,
  SupabaseError
> = (options) => {
  const queryClient = useQueryClient();
  return useMutation(
    ['remove-document'],
    async (documentId) => {
      const response = await removeDocument(supabase, documentId);
      await queryClient.invalidateQueries({
        predicate: (query) => {
          const validKeys = ['documents', 'profiles_users_patients_view'];
          return validKeys.includes(`${query.queryKey[0]}`);
        },
      });
      return response;
    },
    options
  );
};
