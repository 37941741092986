import { Controller, FieldValues } from 'react-hook-form';
import { Input, InputProps } from '../input';
import { RhfProps } from './types';
import { useTranslation } from 'react-i18next';
import React from 'react';

export type InputRhfProps<T extends FieldValues = FieldValues> = InputProps &
  RhfProps<T>;

export const InputRhf = React.forwardRef<HTMLInputElement, InputRhfProps>(
  (
    {
      name,
      control,
      rules = {},
      transformation,
      defaultValue,
      onBlur,
      onChange: customOnChange,
      type,
      id,
      ...inputProps
    }: InputRhfProps,
    ref
  ) => {
    const { t } = useTranslation();
    if (inputProps.required) {
      rules.required = t('This field is required.');
    }
    return (
      <Controller
        name={name}
        rules={rules}
        control={control}
        defaultValue={defaultValue}
        render={({
          field: { onChange, value, onBlur: onBlurRhf, ref: fieldRef },
          fieldState: { error },
        }) => {
          // Для type="file" не передаём value, так как оно не управляется напрямую
          const isFileInput = type === 'file';
          return (
            <Input
              {...inputProps}
              id={id}
              {...(isFileInput
                ? {} // Не передаём value для type="file"
                : {
                    value: transformation ? transformation(value) : value ?? '',
                  })}
              onBlur={(e) => {
                onBlur?.(e);
                onBlurRhf();
              }}
              ref={ref || fieldRef}
              variant={error ? 'danger' : undefined}
              error={!!error}
              onChange={(e) => {
                onChange(e); // Передаём событие в Controller
                if (customOnChange) customOnChange(e); // Вызываем кастомный onChange
              }}
              helperText={error ? error.message : inputProps.helperText}
              type={type}
            />
          );
        }}
      />
    );
  }
);
