import { useMutation } from '@tanstack/react-query';
import { UploadDocumentRequest, UploadDocumentResponse } from '../model/types';
import uploadDocument from './upload-document';
import { supabase } from '@shared/supabase';
import { MutationProvider, SupabaseError } from '@shared/types';

export const useUploadDocumentMutation: MutationProvider<
    UploadDocumentResponse,
    UploadDocumentRequest,
    SupabaseError
> = (options) => {

    return useMutation(async (payload) => {
        return await uploadDocument(supabase, payload);
    }, options);
};
