import { useQuery } from '@tanstack/react-query';
import { getPrescriptionMedicine } from './get-prescription-medicine';
import { PrescriptionMedicineResponse } from '../model/types';
import { supabase } from '@shared/supabase';
import { QueryProvider, SupabaseError } from '@shared/types';

export const useGetPrescriptionMedicineQuery: QueryProvider<
  unknown,
  PrescriptionMedicineResponse,
  SupabaseError,
  number
> = (options, prescriptionMedicineId) => {
  return useQuery(
    ['prescription-medicine', prescriptionMedicineId],
    async () =>
      await getPrescriptionMedicine(supabase, prescriptionMedicineId!),
    options
  );
};
