import { useMutation } from '@tanstack/react-query';
import { serverErrorParser, supabase } from 'src/shared/supabase';
import { MutationProvider, SupabaseError } from 'src/shared/types';
import { SignInRequest, SignInResponse } from '../model/types';

const useSignInMutation: MutationProvider<SignInResponse, SignInRequest, SupabaseError> = () => {
  return useMutation(async (payload) => {
    const { data, error } = await supabase.functions.invoke('otpSignin', {
      body: {
        phone: payload.phone,
      },
    });
    if (error) {
      return await serverErrorParser(error);
    }
    return data;
  });
};

export default useSignInMutation;
