import { serverErrorParser } from '@shared/supabase';
import { Client, CreatePrescriptionMedicinePayload } from '../model/types';

export async function createPrescriptionMedicine(
  client: Client,
  payload: CreatePrescriptionMedicinePayload
) {
  const { data, error } = await client
    .from('prescription_medicines')
    .insert(payload)
    .select('prescription_id, id')
    .single();

  if (error) {
    return await serverErrorParser(error);
  }
  return data;
}
