import React from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from 'src/shared/layouts';
import { PatientList } from '@entities/patients';
import { BreadCrumbs, ScrollToTop } from '@shared/ui';

const PatientsPage = () => {
  const { t } = useTranslation();
  return (
    <Page header={<BreadCrumbs />}>
      <ScrollToTop />
      <h1 className="text-2xl font-semibold text-gray-900 mb-4">
        {t('Patients')}
      </h1>
      <PatientList />
    </Page>
  );
};

export default PatientsPage;
