import { serverErrorParser } from '@shared/supabase';
import { Client, EditPrescriptionPayload } from '../model/types';

export async function updatePrescription(
    client: Client,
    payload: EditPrescriptionPayload
) {
    const { data, error } = await client
        .from('prescriptions')
        .update(payload)
        .eq('id', payload.id)
        .select('id')
        .single();

    if (error) {
        return await serverErrorParser(error);
    }
    return data;
}
