import React from 'react';
import { useTranslation } from 'react-i18next';

const days = [
  { id: '0', label: 'Sn' },
  { id: '1', label: 'Mn' },
  { id: '2', label: 'Tu' },
  { id: '3', label: 'Wd' },
  { id: '4', label: 'Th' },
  { id: '5', label: 'Fr' },
  { id: '6', label: 'St' },
];

const weekOrder = ['1', '2', '3', '4', '5', '6', '0'];

const sortedDays = days.sort(
  (a, b) => weekOrder.indexOf(a.id) - weekOrder.indexOf(b.id)
);

interface SelectMultipleDaysProps {
  selectedDays: string[];
  onChange: (days: string[]) => void;
}

const SelectMultipleDays = ({
  selectedDays = [],
  onChange,
}: SelectMultipleDaysProps) => {
  const { t } = useTranslation();
  const toggleDay = (dayId: string) => {
    const newSelectedDays = selectedDays.includes(dayId)
      ? selectedDays.filter((id) => id !== dayId)
      : [...selectedDays, dayId];

    onChange(newSelectedDays);
  };

  return (
    <div className="flex flex-wrap justify-between">
      {sortedDays.map((day) => (
        <button
          key={day.id}
          type="button"
          className={`px-3 py-1 rounded-md text-sm border transition-all ${
            selectedDays.includes(day.id)
              ? 'bg-purple-500 text-white'
              : 'bg-gray-200 text-gray-700'
          } hover:border-purple-700 hover:border-[1.5px] focus:outline-none`}
          onClick={() => toggleDay(day.id)}
        >
          {t(day.label)}
        </button>
      ))}
    </div>
  );
};

export default SelectMultipleDays;
