import React from 'react';
import { useTranslation } from 'react-i18next';
import { PencilIcon } from '@heroicons/react/24/solid';
import { Button, DisclosureDropdown } from '@shared/ui';
import { PrescriptionMedicineResponse } from '../model/types';
import { DateUtils } from '@shared/utils';

type PrescriptionMedicineEditFormProps = {
  prescriptionMedicine: PrescriptionMedicineResponse;
  changeMedicine?: boolean;
  toggleMedicineSlideOver?: () => void;
  renderButtonSlot?: (prescriptionMedicineId: number) => React.ReactNode;
};

const PrescriptionMedicine: React.FC<PrescriptionMedicineEditFormProps> = ({
  prescriptionMedicine,
  changeMedicine,
  toggleMedicineSlideOver,
  renderButtonSlot,
}) => {
  const { t } = useTranslation();

  const daysMapping: Record<string, string> = {
    '0': t('Sn'),
    '1': t('Mn'),
    '2': t('Tu'),
    '3': t('Wd'),
    '4': t('Th'),
    '5': t('Fr'),
    '6': t('St'),
  };

  const intervalMapping: Record<
    NonNullable<PrescriptionMedicineResponse['interval_type']>,
    string
  > = {
    IntervalMode: t('Days interval'),
    CyclicMode: t('Reception days'),
    DaysOfWeek: t('Days of the week'),
  };

  const formatIntakeTimes = (times?: string[]) =>
    times
      ?.map((time) => (time ? DateUtils.format(time, 'HH:mm') : 'N/A'))
      .join(', ');

  const formatIntervalValue = () => {
    if (prescriptionMedicine?.interval_type === 'DaysOfWeek') {
      return (
        prescriptionMedicine.interval_value
          ?.map(Number)
          .sort((a, b) => (a === 0 ? 1 : b === 0 ? -1 : a - b))
          .map((day) => daysMapping[day] || day)
          .join(', ') || 'N/A'
      );
    }
    return prescriptionMedicine?.interval_value?.[0] || 'N/A';
  };

  const medicineDescription = [
    { title: t('Medicine name'), value: prescriptionMedicine?.medicine_name },
    {
      title: t('Interval type'),
      value: intervalMapping[prescriptionMedicine?.interval_type!] || 'N/A',
    },
    {
      title: intervalMapping[prescriptionMedicine?.interval_type!],
      value: formatIntervalValue(),
    },
    prescriptionMedicine?.interval_type === 'CyclicMode' && {
      title: t('Break days'),
      value: prescriptionMedicine?.interval_value?.[1] || 'N/A',
    },
    {
      title: t('Intake time'),
      value: formatIntakeTimes(prescriptionMedicine?.intake_times!),
    },
    {
      title: t('Start date'),
      value: DateUtils.format(prescriptionMedicine?.start_date || 'N/A'),
    },
    {
      title: t('Treatment period'),
      value: prescriptionMedicine?.treatment_period || 'N/A',
    },
    {
      title: t('Intake quantity'),
      value: prescriptionMedicine?.dosage || 'N/A',
    },
    {
      title: t('Remind'),
      value: prescriptionMedicine?.remind ? t('On') : t('Off'),
    },
  ].filter(Boolean) as { title: string; value: string }[];

  return (
    <div className="flex flex-wrap py-1 items-center w-full rounded-lg">
      <DisclosureDropdown title={prescriptionMedicine?.medicine_name}>
        <div className="grid grid-cols-2 gap-2">
          {medicineDescription.map(({ title, value }) => (
            <div
              key={title}
              className="flex flex-col p-4 border-b-2 border-gray-200"
            >
              <dt className="text-sm font-medium text-gray-900">{title}</dt>
              <dd className="mt-1 text-sm text-gray-500">{value}</dd>
            </div>
          ))}
        </div>
        {changeMedicine && (
          <div className="flex flex-row gap-2 justify-end mt-4">
            {renderButtonSlot && renderButtonSlot(prescriptionMedicine.id)}
            <Button className="w-1/4" onClick={toggleMedicineSlideOver}>
              <PencilIcon className="h-5 w-5" />
            </Button>
          </div>
        )}
      </DisclosureDropdown>
    </div>
  );
};

export default PrescriptionMedicine;
