import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getRoutePatients } from '@shared/const/router';
import { useAuth } from '@entities/session';
import { LoginForm } from '@features/auth';
import { Loader } from '@shared/ui/loader';

type AuthPageProps = {};

const AuthPage = (props: AuthPageProps) => {
  const location = useLocation();
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return <Loader />;
  }

  if (user) {
    if (location.state?.from) {
      return <Navigate to={location.state.from} state={{ from: location }} replace />;
    }
    return <Navigate to={getRoutePatients()} state={{ from: location }} replace />;
  }

  return <LoginForm />;
};

export default AuthPage;
