import {
  AppRoutes,
  getRouteNewPrescription,
  getRoutePatient,
  getRoutePatients,
  getRoutePrescription,
  getRoutePrescriptions,
  getRouteProfile,
  getRouteSeizures,
  getRouteSeizuresPatient,
  getRouteAuth,
  getRouteMain,
  getRouteActivity,
  getRoutePatientActivity,
} from '@shared/const/router';
import { AppRoutesProps } from '@shared/types/router';
import PatientsPage from '@pages/patients-page';
import NotFoundPage from '@pages/not-found-page';
import AuthPage from '@pages/auth-page';
import MainPage from '@pages/main-page';
import ProfilePage from '@pages/profile-page';
import SeizuresPage from '@pages/seizure-patients-page';
import PatientSeizuresPage from '@pages/seizures-page';
import PrescriptionsPage from '@pages/prescriptions-page';
import ActivityPage from '@pages/activity-page';
import PatientActivityPage from '@pages/patient-activity-page';
import EditPrescriptionPage from '@pages/edit-prescription-page';
import PatientDetailsPage from '@pages/patient-details-page';
import NewPrescriptionPage from '@pages/new-prescription-page';

const defaultRoutesProps: AppRoutesProps = { authOnly: true };
export const routeConfig: Record<AppRoutes, AppRoutesProps> = {
  [AppRoutes.MAIN]: {
    ...defaultRoutesProps,
    path: getRouteMain(),
    element: <MainPage />,
  },
  [AppRoutes.NEW_PRESCRIPTION]: {
    ...defaultRoutesProps,
    path: getRouteNewPrescription(':id'),
    element: <NewPrescriptionPage />,
  },
  [AppRoutes.PATIENTS]: {
    ...defaultRoutesProps,
    path: getRoutePatients(),
    element: <PatientsPage />,
  },
  [AppRoutes.PATIENT]: {
    ...defaultRoutesProps,
    path: getRoutePatient(':id'),
    element: <PatientDetailsPage />,
  },
  [AppRoutes.ACTIVITY]: {
    ...defaultRoutesProps,
    path: getRouteActivity(),
    element: <ActivityPage />,
  },
  [AppRoutes.PATIENT_ACTIVITY]: {
    ...defaultRoutesProps,
    path: getRoutePatientActivity(':id'),
    element: <PatientActivityPage />,
  },
  [AppRoutes.PRESCRIPTIONS]: {
    ...defaultRoutesProps,
    path: getRoutePrescriptions(),
    element: <PrescriptionsPage />,
  },
  [AppRoutes.PRESCRIPTION]: {
    ...defaultRoutesProps,
    path: getRoutePrescription(':id'),
    element: <EditPrescriptionPage />,
  },
  [AppRoutes.PROFILE]: {
    ...defaultRoutesProps,
    path: getRouteProfile(),
    element: <ProfilePage />,
  },
  [AppRoutes.SEIZURES]: {
    ...defaultRoutesProps,
    path: getRouteSeizures(),
    element: <SeizuresPage />,
  },
  [AppRoutes.SEIZURES_PATIENT]: {
    ...defaultRoutesProps,
    path: getRouteSeizuresPatient(':id'),
    element: <PatientSeizuresPage />,
  },
  [AppRoutes.AUTH]: {
    ...defaultRoutesProps,
    authOnly: false,
    noAuthOnly: true,
    path: getRouteAuth(),
    element: <AuthPage />,
  },
  [AppRoutes.NOT_FOUND]: {
    ...defaultRoutesProps,
    authOnly: false,
    public: true,
    path: '*',
    element: <NotFoundPage />,
  },
};

export const routeConfigNoAuthOnly = Object.fromEntries(
  Object.entries(routeConfig).filter(([key, value]) => value.noAuthOnly)
);
export const routeConfigAuthOnly = Object.fromEntries(
  Object.entries(routeConfig).filter(([key, value]) => value.authOnly)
);
export const routeConfigPublic = Object.fromEntries(
  Object.entries(routeConfig).filter(([key, value]) => value.public)
);
export { AppRoutes };
