import moment from 'moment';
import { DayType, Message } from '../model/types';
import { TFunction } from 'react-i18next';

const monthNames = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

const dayAgo = (
  date: string | undefined,
  t: TFunction<'translation', undefined>
) => {
  if (!date) return '';

  // Преобразуем даты в объекты moment, обрезая время до начала дня
  const messageDate = moment(date).startOf('day'); // Начало дня сообщения
  const today = moment().startOf('day'); // Начало текущего дня
  const yesterday = moment().subtract(1, 'days').startOf('day'); // Начало вчерашнего дня

  // Сравниваем только даты
  if (messageDate.isSame(today, 'day')) {
    return t('today'); // "сегодня"
  }
  if (messageDate.isSame(yesterday, 'day')) {
    return t('yesterday'); // "вчера"
  }

  // Форматируем полную дату для остальных случаев
  const day = moment(date).date();
  const monthNumber = moment(date).month(); // 0-11
  const year = moment(date).year();
  return `${day} ${t(monthNames[monthNumber])} ${year}`;
};

function groupMessages(messages: Message[]) {
  const groupedMessages: { [key: string]: Message[] } = {};
  messages.map((message) => {
    const messageDay = moment(message.created_at).format('YYYY-MM-DD');
    if (groupedMessages[messageDay]) {
      groupedMessages[messageDay].unshift(message);
    } else {
      groupedMessages[messageDay] = [message];
    }
    return groupedMessages;
  });
  return groupedMessages;
}

function generateItems(messages: Message[]) {
  const daysWithMessages = groupMessages(messages);
  const sortedDaysAsc = Object.keys(daysWithMessages).sort(
    (x, y) => moment(x, 'YYYY-MM-DD').unix() - moment(y, 'YYYY-MM-DD').unix()
  );

  const newMessages: (Message | DayType)[] = [];

  sortedDaysAsc.map((date) => {
    const sortedMessagesAsc = daysWithMessages[date];
    return newMessages.push(
      { type: 'day', date, id: date },
      ...sortedMessagesAsc
    );
  });
  return newMessages;
}

export { generateItems, dayAgo };
