import React from 'react';
import { SlideOver, Preloader } from '@shared/ui';
import { useGetPrescriptionMedicineQuery } from '@entities/prescription-medicines';
import { MedicineForm } from './medicine-form/medicine-form';
import { ExtendedUpdatePrescriptionMedicinePayload } from '../model/types';
import useUpdatePrescriptionMedicineMutation from '../api/useUpdatePrescriptionMedicineMutation';
import { formatMedicinePayload } from '../utils/medicineUtils';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import useCreatePrescriptionMedicineMutation from '../api/useCreatePrescriptionMedicineMutation';
import { getMedicineDefaultValues } from '../utils/medicineUtils';

interface IMedicineSlideOverFormProps {
  prescriptionMedicineId: number | null;
  prescriptionId: string;
  onCancel: () => void;
  isOpen: boolean;
}

const MedicineSlideOverForm: React.FC<IMedicineSlideOverFormProps> = ({
  prescriptionMedicineId,
  prescriptionId,
  onCancel,
  isOpen,
}) => {
  const isNewMedicine = !prescriptionMedicineId;
  const { t } = useTranslation();
  const { data: medicine, isLoading } = useGetPrescriptionMedicineQuery(
    { enabled: !isNewMedicine },
    Number(prescriptionMedicineId)
  );
  const updateMutation = useUpdatePrescriptionMedicineMutation();
  const createMutation = useCreatePrescriptionMedicineMutation();
  const defaultValues = getMedicineDefaultValues(medicine);

  const onFormSubmit = (data: ExtendedUpdatePrescriptionMedicinePayload) => {
    const currentPayload = {
      ...formatMedicinePayload(data),
      prescription_id: Number(prescriptionId),
    };
    if (isNewMedicine) {
      createMutation.mutate(currentPayload, {
        onSuccess: () => {
          toast.success(`${t('You have successfully added the medicine')}`);
          onCancel();
        },
        onError: (error) => toast.error(String(error)),
      });
    } else {
      updateMutation.mutate(currentPayload, {
        onSuccess: () => {
          toast.success(
            `${t('You have successfully updated your medication')}`
          );
          onCancel();
        },
        onError: (error) => toast.error(String(error)),
      });
    }
  };

  return (
    <SlideOver isOpen={isOpen} onClose={onCancel}>
      {!isNewMedicine && isLoading ? (
        <div className="h-[70vh] col-span-7 items-center flex justify-center">
          <Preloader size="xl" />
        </div>
      ) : (
        <MedicineForm
          onSubmit={onFormSubmit}
          isLoading={
            isNewMedicine ? createMutation.isLoading : updateMutation.isLoading
          }
          defaultValues={defaultValues}
          isNewMedicine={isNewMedicine}
        />
      )}
    </SlideOver>
  );
};

export default MedicineSlideOverForm;
