import { cn } from '@utils';
import { FormContainerBaseTypes } from './types';
import React from 'react';

type FormContainerProps = FormContainerBaseTypes & {
  id?: string;
  children: React.ReactNode;
  className?: string;
};

export function FormFieldContainer({
  label,
  children,
  helperText,
  id,
  required,
  error,
  labelInline,
  className,
  ...rest
}: FormContainerProps) {
  return (
    <div className={cn('w-full', { 'sm:grid sm:grid-cols-3 sm:gap-4 items-center': labelInline }, className)} {...rest}>
      {label && (
        <label htmlFor={id} className="block text-sm text-start font-medium leading-6">
          {label} {required && <span className="text-danger">*</span>}
        </label>
      )}
      <div className={cn('mt-2', { 'col-span-2 sm:mt-0': labelInline })}>
        {children}
        {(error || helperText) && (
          <p
            className={cn('text-sm text-gray-500 mt-2', {
              'text-danger': error,
            })}
            id={id + (error ? '-error' : '-description')}
          >
            {helperText}
          </p>
        )}
      </div>
    </div>
  );
}
