import { serverErrorParser } from '@shared/supabase';
import { Client } from '@shared/types';

export async function removePrescription(
    client: Client,
    prescriptionId: number
) {
    const { error } = await client
        .from('prescriptions')
        .delete()
        .eq('id', prescriptionId)
        .maybeSingle();

    if (error) {
        return await serverErrorParser(error);
    }
    return { status: 'success' };
}
