import { QueryProvider, SupabaseError } from '@shared/types';
import { useQuery } from '@tanstack/react-query';
import { getPrescription } from './get-prescription';
import { PrescriptionWithMedicines } from '../model/types';
import { supabase } from '@shared/supabase';

export const useGetPrescriptionQuery: QueryProvider<
    unknown,
    PrescriptionWithMedicines,
    SupabaseError,
    number
> = (options, prescriptionId) => {

    return useQuery(
        ['prescription', prescriptionId],
        async () => {
            const prescription = await getPrescription(supabase, prescriptionId!);
            return prescription;
        },
        options
    );
};
