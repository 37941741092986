import { serverErrorParser } from '@shared/supabase';
import { EditDocumentPayload } from '../model/types';
import { Client } from '@shared/types';

export async function updateDocument(
    client: Client,
    payload: EditDocumentPayload
) {
    const { data, error } = await client
        .from('documents')
        .update(payload)
        .eq('id', payload.id)
        .select('id')
        .single();

    if (error) {
        return await serverErrorParser(error);
    }
    return data;
}
