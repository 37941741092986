import React, { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { Image } from '@shared/ui';
import { ProfilesUsersPatientsView } from '@entities/patients/model/types';
import Chat from '@features/chat/ui/chat';

interface Props {
  patient: ProfilesUsersPatientsView;
}

const PatientInfo = ({ patient }: Props) => {
  const { t } = useTranslation();

  const patientForChat = {
    userId: patient.patient_profile_user_id || '',
    name: `${patient.profile_first_name} ${patient.profile_second_name}`,
    avatar: { url: patient.profile_avatar_url },
  };

  return (
    <Popover>
      {() => (
        <>
          <div className="pointer-events-auto w-full">
            <div className="rounded-2xl bg-white">
              <div className="pb-1">
                <div className="flex pt-5 justify-center">
                  <Image
                    url={patient.profile_avatar_url || ''}
                    alt="avatar"
                    className="h-48 w-48 inline-block rounded-full object-cover bg-slate-600 ring-2 ring-white"
                  />
                </div>
                <div className="mt-6">
                  <div className="sm:flex-1">
                    <div className="flex items-center justify-center">
                      <h3 className="text-xl text-center font-bold items-center text-gray-900 sm:text-2xl">
                        {`${patient.profile_first_name} ${patient.profile_second_name} ${patient.profile_third_name}`}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="break-all px-4 pt-5 sm:px-0 sm:pt-0">
                <dl className="sm:grid sm:grid-cols-2 space-y-2 sm:space-y-0 gap-4 p-4">
                  <div className="col-span-2 border border-violet-200 p-2 rounded-lg bg-gray-50">
                    <dt className="text-sm font-medium text-black sm:w-40 sm:flex-shrink-0">
                      {t('Address')}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-600 sm:col-span-2">
                      {patient.profile_address}
                    </dd>
                  </div>
                  <div className="col-span-1 border border-violet-200 p-2 rounded-lg bg-gray-50">
                    <dt className="text-sm font-medium text-black sm:w-40 sm:flex-shrink-0">
                      {t('Diagnosis')}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-600 sm:col-span-2">
                      {patient.patient_diagnosis}
                    </dd>
                  </div>
                  <div className="col-span-1 border border-violet-200 p-2 rounded-lg bg-gray-50">
                    <dt className="text-sm font-medium text-black sm:w-40 sm:flex-shrink-0">
                      {t('Epilepsy type')}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-600 sm:col-span-2">
                      {t(patient.patient_epilepsy_type ?? 'unknown')}
                    </dd>
                  </div>
                </dl>
              </div>
              <div className="flex justify-end w-full flex-wrap space-y-3 sm:space-y-0 sm:space-x-3">
                <Popover.Button className="m-5 w-full md:w-1/3 items-center justify-center rounded-md border border-transparent bg-violet-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-violet-800 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2">
                  {t('Send message')}
                </Popover.Button>
              </div>
            </div>
          </div>
          <div className="fixed top-[20vh] right-[300px] z-50 sm:z-10 md:right-[440px]">
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="fixed pl-0 sm:right-8 right-0 sm:bottom-28 bottom-0 z-10 transform mt-3 w-screen max-w-screen sm:max-w-[400px] opacity-100 translate-y-0">
                <div className="grid bg-white rounded-0 sm:rounded-xl shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden h-screen sm:h-[500px]">
                  <div className="col-span-1 flex flex-none flex-col divide-y z-10 divide-gray-100 overflow-y-auto">
                    <Chat withUser={patientForChat} />
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </div>
        </>
      )}
    </Popover>
  );
};

export default PatientInfo;
