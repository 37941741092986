import { PrescriptionMedicine } from '@entities/prescriptions/model/types';
import { create } from 'zustand';

type StateType = {
  medicines: PrescriptionMedicine[];
  clearMedicinesState: () => void;
  addMedicine: (medicine: PrescriptionMedicine) => void;
  removeMedicine: (medicineId: number) => void;
  updateMedicine: (medicine: PrescriptionMedicine) => void;
};

export const usePrescriptionMedicinesStore = create<StateType>((set) => {
  return {
    medicines: [],

    addMedicine: (medicine: PrescriptionMedicine) => {
      set((state) => ({
        medicines: [...state.medicines, { ...medicine }],
      }));
    },

    updateMedicine: (medicine: PrescriptionMedicine) => {
      set((state) => ({
        medicines: state.medicines.map((prevMedicine) =>
          prevMedicine.id === medicine.id ? medicine : prevMedicine
        ),
      }));
    },
    removeMedicine: (medicineId: number) => {
      set((state) => ({
        medicines: [
          ...state.medicines.filter(
            (prevMedicine) => prevMedicine.id !== medicineId
          ),
        ],
      }));
    },

    clearMedicinesState: () => {
      set({ medicines: [] });
    },
  };
});
