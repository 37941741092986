import { useQuery } from '@tanstack/react-query';
import { getProfile } from './get-profile';
import { ProfileWithDoctorResponse } from '../model/types';
import { supabase } from '@shared/supabase';
import { QueryProvider, SupabaseError } from '@shared/types';

export const useGetProfileQuery: QueryProvider<
  unknown,
  ProfileWithDoctorResponse,
  SupabaseError,
  string
> = (options, userId) => {
  return useQuery(
    ['doctor-profile'],
    async () => await getProfile(supabase, userId!),
    options
  );
};
