import { serverErrorParser } from '@shared/supabase';
import { Client, Patient } from '../../model/types';

export async function getPatientDetails(
    client: Client,
    patient_profile_id: Patient['profile_user_id']
) {
    const { data, error } = await client
        .from('profiles_users_patients_view')
        .select('*')
        .eq('patient_profile_user_id', patient_profile_id || '')
        .limit(1)
        .maybeSingle()
        .throwOnError();

    if (error) {
        return await serverErrorParser(error);
    }

    return data;
}
