import { useQuery } from '@tanstack/react-query';
import { getPatientsActivity } from './get-patients-activity-api';
import { PatientsActivityViewType } from '../model/types';
import { QueryProvider, SupabaseError } from '@shared/types';
import { supabase } from '@shared/supabase';

export const useGetPatientsActivityQuery: QueryProvider<
  unknown,
  PatientsActivityViewType[],
  SupabaseError,
  string
> = (options, userId) => {
  return useQuery(
    ['patient-activity', userId],
    async () => await getPatientsActivity(supabase, userId!),
    options
  );
};
