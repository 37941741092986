import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CreatePrescriptionPayload } from '../model/types';
import { createPrescription } from './create-prescription';
import { supabase } from '@shared/supabase';
import { MutationProvider, SupabaseError } from '@shared/types';

const useCreatePrescriptionMutation: MutationProvider<
    unknown,
    CreatePrescriptionPayload,
    SupabaseError
> = (options) => {
    const queryClient = useQueryClient();
    return useMutation(
        ['create-prescription'],
        async (payload) => {
            const response = await createPrescription(supabase, payload);
            await queryClient.invalidateQueries({ queryKey: ['prescriptions'] });
            await queryClient.invalidateQueries({ queryKey: ['medicine-intakes'] });
            return response;
        },
        options
    );
};

export default useCreatePrescriptionMutation;
