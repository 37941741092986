import React, { useId, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../button/button';
import { Input } from './input';
import Preloader from '../preloader/preloader';
import { cn, formatBytes } from '@utils';

type FileFormatsUnionType = 'png' | 'jpeg' | 'jpg' | 'stl' | 'obj' | 'zip' | 'rar' | '7z';

type HelperTextProps = { text: string; isError?: boolean };

export type InputPickerProps = React.HTMLProps<HTMLInputElement> & {
  acceptFileFormats?: FileFormatsUnionType[] | undefined;
  maxSizeKB?: number;
  isLoading?: boolean;
  setFileForUpload?: (file: File) => void;
  onRemove?: () => void;
  defaultFileName?: string | null;
  disableRemove?: boolean;
  fileExtension?: string;
  replaceButton?: boolean;
  buttonVariant?: Parameters<typeof Button>[0]['variant'];
};

// eslint-disable-next-line react/display-name
const InputPicker = React.forwardRef(
  (
    {
      acceptFileFormats,
      maxSizeKB,
      setFileForUpload,
      isLoading,
      onRemove,
      defaultFileName,
      disableRemove,
      fileExtension,
      buttonVariant,
      replaceButton,
      ...rest
    }: InputPickerProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const [fileName, setFileName] = useState<string | null>(defaultFileName || null);
    const [extensionError, setExtensionError] = useState<string | null>(null);
    const genId = useId();
    const id = rest.id || genId;
    const currentInputProps = {
      ...rest,
    };
    const { t } = useTranslation();
    const isDownloaded = Boolean(fileName && !isLoading);

    const getButtonTitle = () => {
      if (replaceButton) {
        return t('Replace');
      }
      if (isDownloaded) {
        return t('Remove');
      }
      return t('__UPLOAD_ZIP');
    };

    const pickOrRemoveFile = (event: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
      if (replaceButton) {
        event.currentTarget.click();
      } else if (!fileName) {
        event.currentTarget.click();
      } else {
        if (!disableRemove) {
          onRemove?.();
          setFileName(null);
        }
      }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files && e.target.files[0];
      if (file) {
        if (maxSizeKB) {
          const inputFileSizeKB = file.size / 1024;
          if (inputFileSizeKB > maxSizeKB) {
            setExtensionError(`Max size of uploaded file is ${formatBytes(maxSizeKB * 1024)}.`);
            setFileName(null);
            return false;
          }
        }
        const inputFileExtension = file.name.split('.').pop() as FileFormatsUnionType;
        if (
          acceptFileFormats &&
          !acceptFileFormats.includes(inputFileExtension.toLowerCase() as FileFormatsUnionType)
        ) {
          setExtensionError(`Accept only ${acceptFileFormats.join(', ')}`);
          setFileName(null);
        } else {
          setExtensionError(null);
          setFileForUpload?.(file);
          setFileName(file.name);
          e.target.value = '';
          return;
        }
      }
    };

    const getHelperText = () => {
      // longer, but better than ternary
      if (extensionError) {
        return <HelperText text={extensionError} isError />;
      }

      if (isDownloaded) {
        return <HelperText text={fileName!} />;
      }
    };

    return (
      <div className="relative w-full">
        <Input
          {...currentInputProps}
          ref={ref}
          accept={fileExtension}
          className="hidden"
          id={id}
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor={id} onClick={pickOrRemoveFile}>
          <Button disabled={isLoading} variant={isDownloaded ? 'outline' : buttonVariant} className="mr-2">
            {getButtonTitle()}
            {isLoading && <Preloader className="mr-0" />}
          </Button>
        </label>
        {getHelperText()}
      </div>
    );
  }
);

const HelperText = ({ text, isError }: HelperTextProps) => {
  return <span className={cn('font-medium text-sm truncate', isError && 'text-danger')}>{text}</span>;
};

export { InputPicker };
