import React from 'react';
import clsx from 'clsx';

interface IListBodyProps {
  children: React.ReactNode | undefined | null;
  emptyStateText: string;
  className?: string;
}

const ListBody = ({
  children,
  emptyStateText,
  className = '',
}: IListBodyProps) => {
  return (
    <div className={clsx('space-y-2 divide-y divide-gray-200', className)}>
      {children ?? (
        <div className="bg-white border-violet-200 shadow overflow-hidden px-2 py-4 sm:px-4 sm:rounded-md">
          <div className="my-2 mx-4 text-sm text-gray-900">
            <p className="px-2 mt-1">{emptyStateText}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListBody;
