import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BreadCrumbs, ScrollToTop } from '@shared/ui';
import { Page } from '@shared/layouts';
import { SeizureList } from '@entities/seizures';

const PatientSeizuresPage = () => {
  const { t } = useTranslation();
  const { id: patientUserId } = useParams();

  return (
    <Page header={<BreadCrumbs />}>
      <ScrollToTop />
      <h1 className="text-2xl font-semibold text-gray-900 mb-4">
        {t('Seizures')}
      </h1>
      <SeizureList patientUserId={patientUserId!} />
    </Page>
  );
};

export default PatientSeizuresPage;
