import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Input } from '@shared/ui';
import { useTranslation } from 'react-i18next';

interface Props {
  control: any;
  register: any;
}

const MedicineIntakeTime = ({ control, register }: Props) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'intake_times',
  });

  const intakeTimes =
    fields.length > 0 ? fields : [{ id: 'temp', intake_time: '' }];

  return (
    <div className="border-t border-gray-200 pt-2">
      <label htmlFor="intake_times" className="block text-sm font-medium">
        {t('Intake time')}
      </label>
      {intakeTimes.map((field, index) => (
        <div className="col-start-2 col-span-2" key={field.id}>
          <div className="mt-1 sm:mt-0 flex">
            <Input
              {...register(`intake_times[${index}].intake_time`, {
                // @ts-ignore
                value: field.intake_time || '',
              })}
              required
              type="time"
            />
            {index === 0 && (
              <button
                onClick={() => append({ intake_time: '' })}
                type="button"
                className="inline-flex max-h-10 items-center mt-2 ml-2 p-2 border border-transparent rounded-full shadow-sm text-white bg-violet-800 hover:bg-violet-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet-500"
              >
                <PlusIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            )}
            {index > 0 && (
              <button
                onClick={() => remove(index)}
                type="button"
                className="inline-flex max-h-10 items-center mt-2 ml-2 p-2 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MedicineIntakeTime;
