import { Session, SupabaseClient, User } from '@supabase/supabase-js';
import { Database, Tables } from 'src/shared/supabase';

export enum RoleEnum {
  Admin = 'Admin',
  Patient = 'Patient',
  Doctor = 'Doctor',
}

export type Client = SupabaseClient<Database>;
export type Profile = Omit<Tables<'profiles'>, 'address_location'> & {
  address_location: number[];
};
export type Doctor = Tables<'doctors'>;

export type CreateDoctorProfileRequest = {
  profileData: Partial<Profile>;
  doctorData: Partial<Doctor>;
};
export type CreateDoctorProfileResponse = {
  success: boolean;
  profile: Profile;
  user: User;
};

export type SignInResponse = {
  status: string;
};

export type SignInRequest = {
  phone: string;
};
export type SignInCodeRequest = {
  phone: string;
  code: string;
};

export type SignInCodeResponse = {
  data: {
    user: User;
    session: Session;
  };
  profile: Profile;
};
