import { User } from '@supabase/supabase-js';
import { Client, Message } from '../model/types';
import { serverErrorParser } from '@shared/supabase';

type MessagesPayload = {
  client: Client;
  userId: User['id'];
  chatId: string;
  offset: number;
};

export async function getAllMessages({
  client,
  userId,
  chatId,
  offset = 0,
}: MessagesPayload): Promise<Message[]> {
  const { data, error } = await client
    .from('messages')
    .select('*, documents(*)')
    .or(`and(sender_id.eq.${userId},receiver_id.eq.${chatId}),and(sender_id.eq.${chatId},receiver_id.eq.${userId})`)
    .order('created_at', { ascending: false })
    .range(offset, offset + 24);

  if (error) {
    throw await serverErrorParser(error);
  }
  return data;
}
