import React from 'react';
import { useTranslation } from 'react-i18next';
import { PlusIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import PatientPrescriptionItem from './patient-prescription-item';
import { DateUtils } from '@shared/utils';
import { ListBody, ListHeader } from '@shared/ui';
import { ProfilesUsersPatientsView } from '@entities/patients/model/types';

interface Props {
  patientPrescriptions: ProfilesUsersPatientsView['prescriptions'];
  patientUserId: string | undefined;
}

const PatientPrescriptionsList = ({
  patientPrescriptions,
  patientUserId,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <ListHeader
        className="mb-2"
        title={t('Prescriptions')}
        actionButton={
          <Link
            type="button"
            className="self-end inline-flex items-center p-2 border border-transparent border-white rounded-full shadow-sm text-white bg-violet-600 hover:bg-violet-800 focus:outline-none focus:ring-1 focus:ring-offset-1"
            to={`/prescriptions/new-prescription/${patientUserId}`}
          >
            <PlusIcon className="h-6 w-6" aria-hidden="true" />
          </Link>
        }
      />
      <ListBody emptyStateText={t('This patient has no prescriptions yet')}>
        {patientPrescriptions.length > 0 ? (
          patientPrescriptions
            .map((prescription) => (
              <PatientPrescriptionItem
                description={prescription.description ?? ''}
                medicines={prescription.prescription_medicines}
                id={prescription.id}
                key={prescription.id}
                createdAt={DateUtils.format(prescription.created_at!)}
              />
            ))
            .reverse()
        ) : (
          <div className="pt-3 text-center">Список пуст</div>
        )}
      </ListBody>
    </>
  );
};

export default PatientPrescriptionsList;
