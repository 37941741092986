import React, { useMemo } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';

interface Props {
  selectedPage: number;
  onPageSelect: (pageNumber: number) => void;
  itemsOnPage: number;
  totalCount: number;
}

const pageStyle =
  'bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-3 sm:px-4 py-2 border text-sm font-medium';
const activePageStyle =
  'z-10 bg-violet-500 border-violet-500 text-white relative inline-flex items-center px-3 sm:px-4 py-2 border text-sm font-medium';

const PaginationList = ({ selectedPage, totalCount, itemsOnPage, onPageSelect }: Props) => {
  const { t } = useTranslation();
  const lastPage = Math.ceil(totalCount / itemsOnPage);
  const showPagination = totalCount > itemsOnPage;

  const paginationButtons = useMemo(() => {
    const result: string[] = [];

    if (lastPage <= 6) {
      for (let i = 1; i <= lastPage; i++) {
        result.push(i.toString());
      }
    } else {
      result.push('1');

      if (selectedPage <= 3) {
        for (let i = 2; i <= 4; i++) {
          result.push(i.toString());
        }
        result.push('...');
      } else if (selectedPage >= lastPage - 2) {
        result.push('...');

        for (let i = lastPage - 3; i < lastPage; i++) {
          result.push(i.toString());
        }
      } else {
        result.push('...');

        for (let i = selectedPage - 1; i <= selectedPage + 1; i++) {
          result.push(i.toString());
        }

        result.push('...');
      }

      result.push(lastPage.toString());
    }

    return result;
  }, [lastPage, selectedPage]);

  const nextPage = () => {
    if (selectedPage < lastPage) onPageSelect(selectedPage + 1);
  };

  const previousPage = () => {
    if (selectedPage > 1) onPageSelect(selectedPage - 1);
  };

  const goToPage = (page: string) => {
    if (!Number.isNaN(Number(page))) onPageSelect(Number(page));
  };

  return showPagination ? (
    <div className="px-4 py-1 sm:py-3 flex sm:px-6">
      <div className="flex-1 flex flex-col sm:flex-row items-center justify-between space-y-2">
        <p className="text-sm text-gray-700">
          {t('Showing')} <span className="font-medium">{(selectedPage - 1) * itemsOnPage + 1}</span>
          <span> {t('to')} </span>
          <span className="font-medium">
            {selectedPage * itemsOnPage < totalCount ? selectedPage * itemsOnPage : totalCount}
          </span>
          <span> {t('of')} </span>
          <span className="font-medium">{totalCount}</span> {t('results')}
        </p>
        <div>
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <button
              type="button"
              onClick={previousPage}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">{t('Previous')}</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {paginationButtons.map((button, index) => {
              return (
                <button
                  type="button"
                  key={index} // eslint-disable-line
                  onClick={() => goToPage(button)}
                  className={selectedPage.toString() === button ? activePageStyle : pageStyle}
                >
                  {button}
                </button>
              );
            })}
            <button
              type="button"
              onClick={nextPage}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">{t('Next')}</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  ) : null;
};

export default PaginationList;
