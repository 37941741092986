import { ChevronRightIcon, HomeIcon } from '@heroicons/react/24/solid';
import { getRoutePatients } from '@shared/const/router';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Maybe } from 'src/shared/utils/tsutils';

type BreadcrumbsProps = {
  mappingCrumbsName?: (crumb: string) => Maybe<string>;
};

const BreadCrumbs = ({
  mappingCrumbsName = _.capitalize,
}: BreadcrumbsProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  let currentLink = '';

  const crumbs = location.pathname
    .split('/')
    .filter(Boolean)
    .map((crumb) => {
      currentLink += `/${crumb}`;

      return (
        <li key={crumb}>
          <div className="flex items-center">
            <ChevronRightIcon
              className="h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            <Link
              to={currentLink}
              className="whitespace-nowrap ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
            >
              {t(`${mappingCrumbsName(crumb)}`)}
            </Link>
          </div>
        </li>
      );
    });

  return (
    <nav className="flex overflow-auto h-full" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        <li>
          <div>
            <Link
              to={getRoutePatients()}
              className="text-gray-400 hover:text-gray-500"
            >
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">{t('Home')}</span>
            </Link>
          </div>
        </li>
        {crumbs}
      </ol>
    </nav>
  );
};

export default BreadCrumbs;
