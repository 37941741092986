import { getRoutePatients } from '@shared/const/router';
import { Navigate, useLocation } from 'react-router-dom';

const MainPage = () => {
  const location = useLocation();

  return (
    <Navigate to={getRoutePatients()} state={{ from: location }} replace />
  );
};

export default MainPage;
