import { useEffect } from "react";


export const useChatScroll = (isLoading: boolean, setOffset: React.Dispatch<React.SetStateAction<number>>, chatContainerRef: React.RefObject<HTMLDivElement>) => {

    useEffect(() => {
        const chatContainer = chatContainerRef.current;
        if (!chatContainer) return;

        let previousScrollHeight = chatContainer.scrollHeight;

        const handleScroll = () => {
            if (chatContainer.scrollTop <= 0 && !isLoading) {
                previousScrollHeight = chatContainer.scrollHeight; // Запоминаем текущую высоту
                setOffset((prev) => prev + 25);
            }
        };

        const observer = new MutationObserver(() => {
            if (!chatContainer) return;

            const newScrollHeight = chatContainer.scrollHeight;
            const heightDiff = newScrollHeight - previousScrollHeight;

            if (heightDiff > 0) {
                chatContainer.scrollTop += heightDiff; // Сохраняем позицию
            }

            previousScrollHeight = newScrollHeight;
        });

        observer.observe(chatContainer, { childList: true, subtree: true });

        chatContainer.addEventListener('scroll', handleScroll);
        return () => {
            chatContainer.removeEventListener('scroll', handleScroll);
            observer.disconnect();
        };
    }, [isLoading]);
}