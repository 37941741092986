import { serverErrorParser } from '@shared/supabase';
import { Client, PrescriptionMedicineResponse } from '../model/types';

export async function updatePrescriptionMedicine(
    client: Client,
    payload: PrescriptionMedicineResponse
) {
    const { data, error } = await client
        .from('prescription_medicines')
        .update(payload)
        .eq('id', payload.id)
        .select('prescription_id,id')
        .single();

    if (error) {
        return await serverErrorParser(error);
    }
    return data;
}
