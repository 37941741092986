import { useQuery } from '@tanstack/react-query';
import { PatientRecords, PatientRecordsParam } from '../model/types';
import { getPatientRecords } from './get-patient-records';
import { supabase } from '@shared/supabase';
import { QueryProvider, SupabaseError } from '@shared/types';

export const useGetPatientRecordsQuery: QueryProvider<
  unknown,
  PatientRecords[],
  SupabaseError,
  PatientRecordsParam
> = (options, param) => {
  return useQuery(
    ['patient-records'],
    async () => await getPatientRecords(supabase, param!),
    options
  );
};
