import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  FormRhfProvider,
  InputRhf,
  SubmitButtonRhf,
} from '@shared/ui';
import { DateUtils } from '@shared/utils';
import MedicineIntakeTime from './medicine-intake-time-input';
import { IntervalSettings } from './interval-settings';
import {
  ExtendedUpdatePrescriptionMedicinePayload,
  PrescriptionMedicineResponse,
} from '../../model/types';
import { getMedicineDefaultValues } from '../../utils/medicineUtils';

interface MedicineFormProps {
  onSubmit: (data: ExtendedUpdatePrescriptionMedicinePayload) => void;
  isLoading?: boolean;
  defaultValues?: PrescriptionMedicineResponse;
  isNewMedicine?: boolean;
}

export const MedicineForm: React.FC<MedicineFormProps> = ({
  onSubmit,
  isLoading,
  defaultValues,
  isNewMedicine,
}) => {
  const { t } = useTranslation();
  const emptyDefaultValues = getMedicineDefaultValues();

  return (
    <FormRhfProvider forceFormProvider defaultValues={defaultValues}>
      {({ control, register, reset, watch, resetField }) => {
        const intervalType = watch('interval_type');

        return (
          <>
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div className="pt-4 space-y-6 sm:space-y-5">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {isNewMedicine
                    ? t('New medication plan')
                    : t('Edit medication plan')}
                </h3>
                <div className="space-y-6 sm:space-y-5">
                  <InputRhf
                    name="medicine_name"
                    type="text"
                    label={t('Medicine name')}
                    required
                    maxLength={20}
                  />
                  <InputRhf
                    name="start_date"
                    type="date"
                    rules={{ validate: (value) => !!value || t('required') }}
                    max={DateUtils.toFilterFormat(new Date())}
                    label={t('Start date')}
                    required
                  />
                  <InputRhf
                    name="treatment_period"
                    type="number"
                    rules={{
                      validate: (value) =>
                        (value && value > 0) ||
                        t('The treatment period cannot be negative'),
                    }}
                    label={t('Treatment period')}
                    required
                  />
                  <IntervalSettings
                    control={control}
                    intervalType={intervalType || 'IntervalMode'}
                    resetField={resetField}
                  />
                  <InputRhf
                    name="dosage"
                    label={t('Intake quantity')}
                    required
                    rules={{
                      validate: (value) =>
                        value > 0 || t('Intake quantity cannot be negative'),
                    }}
                    type="number"
                  />
                  <MedicineIntakeTime control={control} register={register} />
                  <Controller
                    control={control}
                    name="remind"
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value ?? false}
                        onChange={field.onChange}
                        label={t('Remind')}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="pt-4">
              <div className="flex justify-start space-x-2">
                <Button
                  type="reset"
                  variant="outline"
                  onClick={() => reset(emptyDefaultValues)}
                >
                  {t('Clear')}
                </Button>
                <SubmitButtonRhf
                  onSubmit={onSubmit}
                  disabled={isLoading}
                  loading={isLoading}
                >
                  {isNewMedicine ? t('Add') : t('Update')}
                </SubmitButtonRhf>
              </div>
            </div>
          </>
        );
      }}
    </FormRhfProvider>
  );
};
