import { Button, ConfirmationModal } from '@shared/ui';
import { TrashIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';

type RemoveMedicineButtonProps = {
  handleRemoveMedicine: () => void;
  showModal: boolean;
  toggleModal: () => void;
  isLoading?: boolean;
};

const RemoveMedicineButton = ({
  handleRemoveMedicine,
  toggleModal,
  showModal,
  isLoading,
}: RemoveMedicineButtonProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Button className="w-1/4" variant="danger" onClick={toggleModal}>
        <TrashIcon className="h-5 w-5" />
      </Button>
      <ConfirmationModal
        isOpen={showModal}
        loading={isLoading}
        onCancel={toggleModal}
        onConfirm={handleRemoveMedicine}
        title={t('Confirm Deletion')}
        confirmText={t('Delete')}
        cancelText={t('Cancel')}
      >
        {t('Are you sure you want to delete this medicine?')}
      </ConfirmationModal>
    </>
  );
};

export default RemoveMedicineButton;
