import React from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import ChevronUpIcon from '@heroicons/react/24/solid/ChevronUpIcon';
import cn from 'src/shared/utils/cn';
import Preloader from '../preloader/preloader';

interface Props {
  title: string | undefined | null;
  children: React.ReactNode;
  isLoading?: boolean;
}

const DisclosureDropdown = ({ title, children, isLoading }: Props) => {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button
            className={cn(
              open ? 'rounded-t-3xl' : 'rounded-3xl',
              'flex w-full justify-between px-4 py-2 z-10 text-left bg-violet-500 text-sm font-medium text-white focus:outline-none focus-visible:ring focus-visible:ring-violet-500 focus-visible:ring-opacity-75'
            )}
          >
            <span className="pl-3">
              {isLoading ? <Preloader size="sm" /> : title}
            </span>
            <ChevronUpIcon
              className={`${
                open ? ' ' : 'rotate-180 transform'
              } h-6 w-6 text-white`}
            />
          </Disclosure.Button>
          <Transition
            className="w-full"
            enter="transition duration-100 ease-out"
            enterFrom="transform -translate-y-10 opacity-0"
            enterTo="transform -translate-y-2 opacity-100"
            leave="transition duration-100 ease-out"
            leaveFrom="transform -translate-y-2 opacity-100"
            leaveTo="transform -translate-y-10 opacity-0"
          >
            <Disclosure.Panel className="px-4 py-2 text-sm text-gray-500 rounded-b-3xl w-550-px bg-violet-200">
              {children}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};

export default DisclosureDropdown;
