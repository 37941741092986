import moment from 'moment';
import { format, isToday, isYesterday } from 'date-fns';

export const DATE_FORMAT = 'DD.MM.YYYY';

type combineDateAndTime = {
  date: Date | undefined;
  time: Date | undefined;
};

export class DateUtils {
  static toFilterFormat(date?: string | Date): string {
    return moment(date).format('YYYY-MM-DD');
  }
  static startOfDay(dateString: moment.MomentInput) {
    return `${moment(dateString).format('YYYY-MM-DD')}T00:00:00.000Z`;
  }

  static formatTimeToISO = (time: string): string => {
    const date = new Date(`1970-01-01T${time}:00`);
    return date.toISOString().split(".")[0] + "Z";
  };

  static format(date?: string | Date, format?: string): string {
    return moment(date).format(format ?? DATE_FORMAT);
  }

  static toDate(date: string): Date {
    return moment(date, DATE_FORMAT).toDate();
  }

  static toFilterString(date: Date, rangeType: 'from' | 'to'): string {
    const d = new Date(date);
    if (rangeType === 'from') {
      d.setHours(0, 0, 0);
    } else {
      d.setHours(23, 59, 59);
    }
    return d.toUTCString();
  }

  static convertToFilterRange(dateRange: [Date, Date] | null) {
    if (dateRange) {
      return {
        from: DateUtils.toFilterString(dateRange[0], 'from'),
        to: DateUtils.toFilterString(dateRange[1], 'to'),
      };
    }
  }

  static countNumberOfMinutes = (date: Date) => {
    const hours = moment(date).hours();
    const minutes = moment(date).minutes();
    return hours * 60 + minutes;
  };

  static combineDateAndTime = ({ date, time }: combineDateAndTime) => {
    const onlyDate = moment(date).format('YYYY-MM-DD');
    const onlyTime = moment(time).utc().format('HH:mm:ss');
    return new Date(`${onlyDate}T${onlyTime}`);
  };

  static convertToMinutes = (sec: number): number => {
    return Math.floor(sec / 60);
  };

  static convertToSeconds = (sec: number): number => {
    return sec % 60;
  };

  static getDayString = (date: Date): string => {
    if (isToday(date)) return 'Today';
    if (isYesterday(date)) return 'Yesterday';
    return format(date, 'MMMM d');
  };

  static translateDate = (dateString: string, t: any) => {
    const [monthString, day] = dateString.split(' ');

    // Translate the month using i18next
    const translatedMonth = t(monthString);

    return day ? `${translatedMonth} ${day}` : `${translatedMonth}`;
  };
}
