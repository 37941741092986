import React, { useMemo } from 'react';
import ChatWidget from './chat-widget';
import { useGetDoctorPatientsQuery } from '@entities/patients';
import { useAuth } from '@entities/session';
import { Person } from '../model/types';

const PatientsChatWidget = () => {
  const { user } = useAuth();
  const { data } = useGetDoctorPatientsQuery({}, user?.id);

  const patients = useMemo(() => {
    return (data?.map((patient) => ({
      id: patient.user_id || '',
      name: `${patient.first_name}  ${patient.second_name}`,
      imageUrl: patient.avatar_url || '',
    })) || []) as Person[];
  }, [data]);

  return <ChatWidget people={patients} />;
};

export default PatientsChatWidget;
