import React, { useId } from 'react';
import { FormFieldContainer } from './form-field-container';
import { FormContainerBaseTypes } from './types';
import { cva, VariantProps } from 'class-variance-authority';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { cn } from '@utils';

const InputVariants = cva(
  'p-2 border-gray-300 rounded-md w-full hover:border-indigo-500 focus:border-indigo-500 transition-colors',
  {
    variants: {
      variant: {
        default:
          'text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-500',
        danger:
          'pr-10 text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

export type InputProps = React.HTMLProps<HTMLInputElement> &
  VariantProps<typeof InputVariants> &
  FormContainerBaseTypes & {
    containerProps?: any;
    multiline?: boolean;
    isPassword?: boolean;
    fullWidth?: boolean;
    passwordIconProps?: any;
    icon?: React.ReactNode;
  };

// eslint-disable-next-line react/display-name
const Input = React.forwardRef(
  (
    {
      required,
      error,
      helperText,
      label,
      labelInline,
      containerProps,
      variant,
      icon,
      multiline,
      ...rest
    }: InputProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const genId = useId();
    const id = rest.id || genId;
    const currentInputProps = {
      ...rest,
    };

    return (
      <FormFieldContainer
        id={id}
        error={error}
        required={required}
        label={label}
        size={rest.size}
        helperText={helperText}
        labelInline={labelInline}
        {...containerProps}
      >
        <div className={cn('relative w-full')}>
          {multiline ? (
            <textarea
              {...(currentInputProps as any)}
              id={id}
              ref={ref as any}
              autoCapitalize={'none'}
              className={cn(
                InputVariants({
                  variant,
                  className: currentInputProps.className,
                }),
                variant === 'danger' && icon && 'pr-16'
              )}
            />
          ) : (
            <input
              {...currentInputProps}
              id={id}
              ref={ref}
              autoCapitalize={'none'}
              className={cn(
                InputVariants({
                  variant,
                  className: currentInputProps.className,
                }),
                variant === 'danger' && icon && 'pr-16'
              )}
            />
          )}
          <div className="absolute inset-y-0 right-0 flex justify-center items-center gap-x-2">
            {icon ? (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className={cn('pr-3', variant === 'danger' && 'pr-0')}
              >
                {icon}
              </div>
            ) : null}
            {variant === 'danger' && (
              <div className="pointer-events-none relative inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-danger"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
        </div>
      </FormFieldContainer>
    );
  }
);

export { Input, InputVariants };
