import React from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Seizure } from '@entities/seizures/model/types';

interface Props {
  patientSeizures: Seizure[];
}

const SeizuresTable = ({ patientSeizures }: Props) => {
  const { t } = useTranslation();

  return (
    <table className="lg:min-w-full min-w-[900px] divide-y divide-gray-300">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-5 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            {t('Data')}
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            {t('Intensity')}
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            {t('Duration')}
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            {t('Trigger')}
          </th>
          <th
            scope="col"
            className="px-1 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            {t('Status')}
          </th>
        </tr>
      </thead>
      <tbody className="divide-y items-center divide-gray-200 bg-white">
        {patientSeizures.map((patient, index) => (
          <tr
            key={patient.id}
            className={index % 2 === 0 ? undefined : 'bg-gray-50'}
          >
            <td className="whitespace-nowrap px-5 py-4 text-sm text-gray-900">
              {`${format(new Date(patient.date!), 'dd-MM-yyyy')}`}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
              {t(patient.intensity!)}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
              {patient.duration} {t('seconds')}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
              {patient.trigger}
            </td>
            <td className="whitespace-nowrap px-1 py-4 text-sm text-gray-900">
              {t(patient.status!)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SeizuresTable;
