import React from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import { cn } from '@utils';

type AsProp<C extends React.ElementType> = {
  as?: C;
};

const TextVariants = cva('text-sm font-normal leading-normal', {
  variants: {
    variant: {
      title: 'text-2xl font-semibold ',
    },
  },
});

type PropsToOmit<C extends React.ElementType, P> = keyof (AsProp<C> & P);

type PolymorphicComponentProp<C extends React.ElementType, Props = {}> = React.PropsWithChildren<Props & AsProp<C>> &
  Omit<React.ComponentPropsWithoutRef<C>, PropsToOmit<C, Props>>;

type PolymorphicComponentPropWithRef<C extends React.ElementType, Props = {}> = PolymorphicComponentProp<C, Props> & {
  ref?: PolymorphicRef<C>;
};

// This is the type for the "ref" only
type PolymorphicRef<C extends React.ElementType> = React.ComponentPropsWithRef<C>['ref'];

// This is the updated component props using PolymorphicComponentPropWithRef
type TextProps<C extends React.ElementType> = PolymorphicComponentPropWithRef<C, VariantProps<typeof TextVariants>>;

// This is the type used in the type annotation for the component
type TextComponent = <C extends React.ElementType = 'span'>(props: TextProps<C>) => React.ReactElement | null;

// eslint-disable-next-line react/display-name
const Text: TextComponent = React.forwardRef(
  <C extends React.ElementType = 'span'>(
    { as, children, variant, ...props }: TextProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const Component = as || 'span';

    return (
      <Component className={cn(TextVariants({ variant, className: props.className }))} ref={ref} {...props}>
        {children}
      </Component>
    );
  }
);

export default Text;
