import React from 'react';

type TableRowProps = React.HTMLProps<HTMLTableRowElement> & {
  className?: string;
  children: React.ReactNode;
};

const TableRow = React.forwardRef<HTMLTableRowElement, TableRowProps>(({ className, children, ...props }, ref) => {
  return (
    <tr ref={ref} className={className} {...props}>
      {children}
    </tr>
  );
});

export default TableRow;
