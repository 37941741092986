import { useMutation, useQueryClient } from '@tanstack/react-query';
import { EditPrescriptionPayload } from '../model/types';
import { SupabaseError, MutationProvider } from '@shared/types';
import { supabase } from '@shared/supabase';
import { updatePrescription } from './update-prescription';

const useUpdatePrescriptionMutation: MutationProvider<
    { id: number },
    EditPrescriptionPayload,
    SupabaseError
> = (options) => {
    const queryClient = useQueryClient();
    return useMutation(
        ['update-prescription'],
        async (payload) => {
            const response = await updatePrescription(supabase, payload);
            await queryClient.invalidateQueries({
                queryKey: ['prescription', response.id],
            });
            await queryClient.invalidateQueries({ queryKey: ['prescriptions'] });
            return response;
        },
        options
    );
};

export default useUpdatePrescriptionMutation;
