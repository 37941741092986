import { Client, PatientRecordsParam } from '../model/types';
import { serverErrorParser } from '@shared/supabase';

export const getPatientRecords = async (
  client: Client,
  param: PatientRecordsParam
) => {
  const { data, error } = await client.rpc('get_user_records', param);

  if (error) {
    serverErrorParser(error);
  }

  return data;
};
