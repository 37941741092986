import { serverErrorParser } from 'src/shared/supabase';
import { Client, CreateDoctorProfileRequest } from '../model/types';

export async function createDoctorProfile(client: Client, { profileData, doctorData }: CreateDoctorProfileRequest) {
  const { data, error } = await client.functions.invoke('createDoctorProfile', {
    body: {
      profileData,
      doctorData,
    },
  });

  if (error) {
    return await serverErrorParser(error);
  }
  return data;
}
