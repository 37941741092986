import { toast } from 'react-toastify';
import RemoveMedicineButton from './remove-medicine-button';
import { useToggle } from 'react-use';
import { usePrescriptionMedicinesStore } from '@entities/prescription-medicines/store';

type RemoveMedicineButtonProps = {
  prescriptionMedicineId: number;
};

const LocalRemovePrescriptionMedicineButton = ({
  prescriptionMedicineId,
}: RemoveMedicineButtonProps) => {
  const [showRemoveWarningModal, toggleRemoveWarningModal] = useToggle(false);
  const { removeMedicine } = usePrescriptionMedicinesStore();

  const handleRemoveMedicine = () => {
    removeMedicine(prescriptionMedicineId);
    toast.success('Вы успешно удалили лекарство');
  };

  return (
    <RemoveMedicineButton
      handleRemoveMedicine={handleRemoveMedicine}
      toggleModal={toggleRemoveWarningModal}
      showModal={showRemoveWarningModal}
    />
  );
};

export default LocalRemovePrescriptionMedicineButton;
