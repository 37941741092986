import { serverErrorParser } from '@shared/supabase';
import { Client, UpdateDoctorProfileRequest } from '../model/types';

export async function updateDoctorProfile(
  client: Client,
  { profileData, doctorData }: UpdateDoctorProfileRequest
) {
  const { data, error } = await client.functions.invoke('updateDoctorProfile', {
    body: {
      profileData,
      doctorData,
    },
  });
  if (error) {
    return await serverErrorParser(error);
  }
  return data;
}
