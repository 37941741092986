import { serverErrorParser } from '@shared/supabase';
import { Client } from '../model/types';
import { User } from '@shared/types';

export async function getPatientsActivity(client: Client, user_id: User['id']) {
  const { data, error } = await client
    .from('patients_activity_view')
    .select('*')
    .eq('doctor_user_id', user_id!)
    .throwOnError();

  if (error) {
    return await serverErrorParser(error);
  }
  return data;
}
