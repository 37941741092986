import { useMutation, useQueryClient } from '@tanstack/react-query';
import { removePrescriptionMedicine } from './remove-prescription-medicine';
import { supabase } from '@shared/supabase';
import { MutationProvider, SupabaseError } from '@shared/types';

export const useRemovePrescriptionMedicineMutation: MutationProvider<
    { status: string },
    { medicineId: number; prescriptionId: number },
    SupabaseError
> = (options) => {
    const queryClient = useQueryClient();
    return useMutation(
        ['remove-prescription-medicine'],
        async ({ medicineId, prescriptionId }) => {
            const response = await removePrescriptionMedicine(supabase, medicineId);
            await queryClient.invalidateQueries({ queryKey: ['medicine-intakes'] });
            await queryClient.invalidateQueries({
                queryKey: ['prescription', prescriptionId],
            });
            return response;
        },
        options
    );
};
