import { User } from '@supabase/supabase-js';
import { Client } from '../model/types';
import { serverErrorParser } from '@shared/supabase';

export async function getPatientSeizures(
  client: Client,
  {
    patientId,
    pageSize,
    pageNumber,
  }: { patientId: User['id']; pageSize: number; pageNumber: number }
) {
  let from = (pageNumber - 1) * pageSize;
  let to = from + pageSize - 1;

  const { data, count, error } = await client
    .from('seizures')
    .select('*', { count: 'exact' })
    .eq('user_id', patientId)
    .range(from, to)
    .order('created_at', { ascending: false })
    .throwOnError();

  if (error) {
    return await serverErrorParser(error);
  }

  return { seizuresData: data, count };
}
