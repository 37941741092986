import { FieldValues, FormProvider, useForm, UseFormProps, UseFormReturn } from 'react-hook-form';
import { ReactNode } from 'react';

export type FormRhfProviderProps<T extends FieldValues = FieldValues> = UseFormProps<T> & {
  children: ((context: UseFormReturn<T>) => ReactNode) | ReactNode;
  forceFormProvider?: boolean;
};

export function FormRhfProvider<T extends FieldValues = FieldValues>({
  children,
  forceFormProvider,
  ...formProps
}: FormRhfProviderProps<T>) {
  const methods = useForm<T>({
    ...formProps,
  });
  return typeof children === 'function' ? (
    forceFormProvider ? (
      <FormProvider {...methods}>{children(methods)}</FormProvider>
    ) : (
      <>{children(methods)}</>
    )
  ) : (
    <FormProvider {...methods}>{children}</FormProvider>
  );
}
