import { serverErrorParser } from '@shared/supabase';
import { Client, Prescription } from '../model/types';

export async function getPrescription(client: Client, id: Prescription['id']) {
    const { data, error } = await client
        .from('prescriptions')
        .select(
            `
      *,
      prescription_medicines(*)
      `
        )
        .eq('id', id)
        .maybeSingle()
        .throwOnError();

    if (error) {
        return await serverErrorParser(error);
    }
    return data;
}
