import React from 'react';
import cn from 'src/shared/utils/cn';
import { Button, IModalProps } from '@shared/ui';
import Modal from '../modal/modal';

export interface IConfirmationModalProps extends IModalProps {
  onConfirm: () => void;
  cancelText?: string;
  confirmText: string;
  loading?: boolean;
  buttonsCenter?: boolean;
}

const ConfirmationModal = ({
  children,
  icon,
  isOpen,
  onCancel,
  onConfirm,
  title,
  cancelText,
  confirmText,
  loading,
  className,
  buttonsCenter,
}: IConfirmationModalProps) => {
  return (
    <Modal
      className={className}
      isOpen={isOpen}
      onCancel={onCancel}
      title={title}
      icon={icon}
    >
      <div className="mt-6 text-lg">{children}</div>
      <div
        className={cn('mt-10 flex flex-col sm:flex-row mb-3 sm:mb-2', {
          'sm:justify-center space-x-6': buttonsCenter,
          'sm:justify-end': !buttonsCenter,
        })}
      >
        {cancelText && (
          <Button
            className="uppercase w-full sm:w-[155px] order-1 sm:-order-1 whitespace-nowrap"
            variant="outline"
            onClick={onCancel}
          >
            {cancelText}
          </Button>
        )}
        <Button
          onClick={onConfirm}
          loading={loading}
          className="uppercase w-full sm:w-[155px] mb-3.5 sm:mb-0 sm:ml-2.5 whitespace-nowrap"
        >
          {confirmText}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
