import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import React, { Suspense, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../ui/loader';
import { Maybe } from 'src/shared/utils/tsutils';
import { CenteredLayout } from 'src/shared/layouts';
import { Button } from 'src/shared/ui';

// MakeFetchingEasy
type QueryBoundariesProps = {
  children: React.ReactNode;
  loaderClassName?: Maybe<string>;
};
const QueryBoundaries = ({ children, loaderClassName }: QueryBoundariesProps) => {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary onReset={reset} FallbackComponent={ErrorView}>
          <Suspense fallback={<LoadingView className={loaderClassName} />}>{children}</Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};

// Spinner
const LoadingView = ({ className }: any) => {
  return <Loader className={className} />;
};

// Error + retry
const ErrorView = ({ error, resetErrorBoundary }: FallbackProps) => {
  const { t } = useTranslation();

  // Close ErrorView if either back/forward browser's buttons clicked
  const pathname = window.location.pathname;
  const originalPathname = useRef(pathname);
  useEffect(() => {
    if (pathname !== originalPathname.current) {
      resetErrorBoundary();
    }
  }, [pathname, resetErrorBoundary]);

  return (
    <CenteredLayout>
      <div className="grid place-items-center gap-y-4">
        <div>{error.message}</div>
        <Button onClick={resetErrorBoundary}>{t('Retry')}</Button>
      </div>
    </CenteredLayout>
  );
};

export default QueryBoundaries;
