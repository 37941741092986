import { serverErrorParser } from "@shared/supabase";
import { Client } from "@shared/types";

export async function removePrescriptionMedicine(
    client: Client,
    medicineId: number
) {
    const { error } = await client
        .from('prescription_medicines')
        .delete()
        .eq('id', medicineId)
        .maybeSingle();

    if (error) {
        return await serverErrorParser(error);
    }
    return { status: 'success' };
}
