import React, { useState } from 'react';
import {
  NoElementFound,
  PageNotFound,
  PaginationList,
  Preloader,
} from '@shared/ui';
import { useTranslation } from 'react-i18next';
import SeizurePatientsTable from '../seizure-patients-table';
import { useGetDoctorPatientsQuery } from '@entities/patients';
import { useAuth } from '@entities/session';

const itemsOnPage = 5;

const SeizurePatientsList = () => {
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);
  const { user } = useAuth();
  const { data, isLoading, isError } = useGetDoctorPatientsQuery({}, user?.id);
  const totalCount: number | undefined = data?.length;

  if (isLoading) {
    return (
      <div className="pt-20">
        <Preloader size="xl" />
      </div>
    );
  }

  if (data?.length === 0) {
    return <NoElementFound title={t('Patients list is empty')} />;
  }

  if (isError) {
    return <PageNotFound />;
  }

  return (
    <div className="pt-4">
      <div className="flex flex-col pb-4">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle px-1 md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
              <SeizurePatientsTable patients={data} />
            </div>
          </div>
        </div>
      </div>
      {totalCount && (
        <PaginationList
          itemsOnPage={itemsOnPage}
          totalCount={totalCount}
          selectedPage={pageNumber}
          onPageSelect={setPageNumber}
        />
      )}
    </div>
  );
};

export default SeizurePatientsList;
