export enum AppRoutes {
  MAIN = 'main',
  AUTH = 'auth',
  PATIENTS = 'patients',
  PATIENT = 'patient',
  PROFILE = 'profile',
  SEIZURES = 'seizures',
  SEIZURES_PATIENT = 'seizures_patient',
  PRESCRIPTIONS = 'prescriptions',
  PRESCRIPTION = 'prescription',
  NEW_PRESCRIPTION = 'new_prescription',
  ACTIVITY = 'activity',
  PATIENT_ACTIVITY = 'patient_activity',
  // last
  NOT_FOUND = 'not_found',
}

export const getRouteMain = () => '/';
export const getRouteAuth = () => '/authentication';
export const getRoutePatients = () => '/patients';
export const getRoutePatient = (id: string) => `/patients/${id}`;
export const getRouteProfile = () => '/profile';
export const getRouteNewPrescription = (id: string) =>
  `/prescriptions/new-prescription/${id}`;
export const getRouteSeizures = () => '/seizures';
export const getRouteActivity = () => '/activity';
export const getRouteSeizuresPatient = (id: string) => `/seizures/${id}`;
export const getRoutePatientActivity = (id: string) => `/activity/${id}`;
export const getRoutePrescriptions = () => '/prescriptions';
export const getRoutePrescription = (id: string) => `/prescriptions/${id}`;

export const AppRouteByPathPattern: Record<string, AppRoutes> = {
  [getRouteMain()]: AppRoutes.MAIN,
  [getRouteAuth()]: AppRoutes.AUTH,
  [getRoutePatients()]: AppRoutes.PATIENTS,
  [getRoutePatient(':id')]: AppRoutes.PATIENT,
  [getRouteProfile()]: AppRoutes.PROFILE,
  [getRouteNewPrescription(':id')]: AppRoutes.NEW_PRESCRIPTION,
  [getRouteSeizures()]: AppRoutes.SEIZURES,
  [getRouteSeizuresPatient(':id')]: AppRoutes.SEIZURES_PATIENT,
  [getRoutePrescriptions()]: AppRoutes.PRESCRIPTIONS,
  [getRoutePrescription(':id')]: AppRoutes.PRESCRIPTION,
};
