import { getRouteAuth } from '@shared/const/router';
import { Loader } from '@shared/ui/loader';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from 'src/entities/session';

interface RequireAuthProps {
  children: JSX.Element;
}

export function RequireAuth({ children }: RequireAuthProps) {
  const { user, isLoading } = useAuth();
  const location = useLocation();

  if (isLoading) {
    return <Loader />;
  }

  if (!user) {
    return <Navigate to={getRouteAuth()} state={{ from: location }} replace />;
  }

  return children;
}
