import { serverErrorParser } from '@shared/supabase';
import { Client, PrescriptionMedicineResponse } from '../model/types';

export async function getPrescriptionMedicine(
    client: Client,
    id: PrescriptionMedicineResponse['id']
) {
    const { data, error } = await client
        .from('prescription_medicines')
        .select('*')
        .eq('id', id)
        .maybeSingle()
        .throwOnError();

    if (error) {
        return await serverErrorParser(error);
    }
    return data;
}
