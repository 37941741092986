import uploadAvatar from './uploadAvatar';
import { supabase } from '@shared/supabase';
import { useMutation } from '@tanstack/react-query';
import { MutationProvider, SupabaseError } from '@shared/types';
import { UploadAvatarRequest, UploadAvatarResponse } from '../model/types';

export const useUploadAvatarMutation: MutationProvider<
  UploadAvatarResponse,
  UploadAvatarRequest,
  SupabaseError
> = (options) => {
  return useMutation(async (payload) => {
    return await uploadAvatar(supabase, payload);
  }, options);
};
