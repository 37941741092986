import { serverErrorParser } from '@shared/supabase';
import { UploadAvatarRequest, UploadAvatarResponse } from '../model/types';
import { Client } from '@shared/types';

export default async function uploadAvatar(
  client: Client,
  { file, fileName }: UploadAvatarRequest
): Promise<UploadAvatarResponse> {
  const { data, error } = await client.storage
    .from('avatars')
    .upload(fileName, file);

  if (error) {
    return await serverErrorParser(error);
  }

  const {
    data: { publicUrl },
  } = client.storage.from('avatars').getPublicUrl(data?.path);
  return { ...data, publicUrl };
}
