import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormRhfProvider, InputRhf, SubmitButtonRhf } from 'src/shared/ui';
import { useAuth } from 'src/entities/session';
import { CreateDoctorProfileResponse, Doctor, Profile, RoleEnum } from '../model/types';
import useCreateDoctorProfileMutation from '../api/useCreateDoctorProfileMutation';

interface SignUpFormProps {
  onSubmit: (data: CreateDoctorProfileResponse) => void;
  profile?: Profile;
}

const SignUpForm = ({ onSubmit, profile }: SignUpFormProps) => {
  const { t } = useTranslation();
  const { isLoading, user } = useAuth();

  const { mutate: createPatientProfile, isLoading: IsLoadingCreatePatientProfile } = useCreateDoctorProfileMutation({
    onSuccess: (data) => {
      onSubmit(data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleCreatePatientProfile = async (payload: Profile & Doctor) => {
    const { specialization, details, ...rest } = payload;

    const doctorPayload = {
      specialization,
      details,
    };
    let profilePayload = null;
    if (!profile) {
      profilePayload = {
        ...rest,
        role: RoleEnum.Doctor,
        user_id: user?.id,
      };
    } else {
      profilePayload = {
        ...profile,
        first_name: rest.first_name,
        second_name: rest.second_name,
        third_name: rest.third_name,
        role: RoleEnum.Doctor,
      };
    }

    createPatientProfile({
      profileData: profilePayload,
      doctorData: doctorPayload,
    });
  };

  return (
    <FormRhfProvider
      defaultValues={{
        first_name: profile?.first_name || '',
        second_name: profile?.second_name || '',
        third_name: profile?.third_name || '',
        specialization: '',
        details: '',
      }}
      forceFormProvider
    >
      {() => (
        <div className="bg-gray-100 h-screen">
          <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">{t('Sign in to your account')}</h2>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <div className="space-y-6">
                  <div>
                    <span className="block text-sm font-medium text-gray-700">{t('First Name')}:</span>
                    <div className="mt-1">
                      <InputRhf name="first_name" autoFocus placeholder={t('First Name')} required />
                    </div>
                  </div>
                  <div>
                    <span className="block text-sm font-medium text-gray-700">{t('Second Name')}:</span>
                    <div className="mt-1">
                      <InputRhf name="second_name" autoFocus placeholder={t('Second Name')} required />
                    </div>
                  </div>
                  <div>
                    <span className="block text-sm font-medium text-gray-700">{t('Third Name')}:</span>
                    <div className="mt-1">
                      <InputRhf name="third_name" autoFocus placeholder={t('Third Name')} required />
                    </div>
                  </div>
                  <div>
                    <span className="block text-sm font-medium text-gray-700">{t('Specialization')}:</span>
                    <div className="mt-1">
                      <InputRhf name="specialization" autoFocus placeholder={t('Specialization')} required />
                    </div>
                  </div>
                  <div>
                    <span className="block text-sm font-medium text-gray-700">{t('Details')}:</span>
                    <div className="mt-1">
                      <InputRhf name="details" autoFocus placeholder={t('Details')} required />
                    </div>
                  </div>
                  <SubmitButtonRhf
                    className="w-full"
                    loading={IsLoadingCreatePatientProfile || isLoading}
                    onSubmit={handleCreatePatientProfile}
                  >
                    {t('Next')}
                  </SubmitButtonRhf>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </FormRhfProvider>
  );
};

export default SignUpForm;
