import { serverErrorParser } from '@shared/supabase';
import { Client, Doctor } from '../../model/types';

export async function getDoctorPatients(
  client: Client,
  user_id: Doctor['profile_user_id']
) {
  const { data, error } = await client
    .from('doctor_patients_view')
    .select('*')
    .eq('profile_user_id', user_id!)
    .throwOnError();

  if (error) {
    return await serverErrorParser(error);
  }
  return data;
}
