import { FormRhfProvider, InputRhf, SubmitButtonRhf } from '@shared/ui';
import { InsertMessagesRequest } from '../model/types';

interface ChatInputprops {
  handleMessageSubmit: (data: InsertMessagesRequest, reset: () => void) => void;
  isSending: boolean;
}

export const ChatInputForm = ({
  handleMessageSubmit,
  isSending,
}: ChatInputprops) => {
  return (
    <FormRhfProvider
      forceFormProvider
      defaultValues={{ text: '' } as InsertMessagesRequest}
    >
      {({ watch, reset, handleSubmit }) => {
        const textValue = watch('text')!;
        const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit((data) => handleMessageSubmit(data, reset))();
          }
        };

        return (
          <div className="flex items-center justify-between w-full p-3 border-t border-gray-100 overflow-hidden gap-1.5">
            <InputRhf
              onKeyDown={onKeyDown}
              autoFocus
              name="text"
              type="text"
              placeholder="Message"
              className="block w-full pl-4 bg-gray-100 rounded-full outline-none focus:text-gray-700 focus:border-fuchsia-600"
            />

            <SubmitButtonRhf
              className="bg-transparent pt-4 m-0 border-none focus:outline-none active:bg-transparent"
              type="submit"
              onSubmit={(data) =>
                handleMessageSubmit(data as InsertMessagesRequest, reset)
              }
              disabled={isSending || !textValue.trim()}
            >
              <svg
                className={`${
                  textValue.trim() ? 'text-fuchsia-600' : 'text-gray-500'
                } w-8 h-8 origin-center transform rotate-90 `}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
              </svg>
            </SubmitButtonRhf>
          </div>
        );
      }}
    </FormRhfProvider>
  );
};
