import { useQuery } from '@tanstack/react-query';
import { getDoctorPatients } from './get-doctor-patients';
import { DoctorPatientsView } from '../../model/types';
import { QueryProvider, SupabaseError } from '@shared/types';
import { supabase } from '@shared/supabase';

export const useGetDoctorPatientsQuery: QueryProvider<
  unknown,
  DoctorPatientsView[],
  SupabaseError,
  string
> = (options, userId) => {
  return useQuery(
    ['doctor-patients', userId],
    async () => await getDoctorPatients(supabase, userId!),
    options
  );
};
