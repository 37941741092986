import React from 'react';
import { useTranslation } from 'react-i18next';
import { BreadCrumbs, ScrollToTop } from '@shared/ui';
import { Page } from '@shared/layouts';
import { ActivityPatientsList } from '@entities/activity';

const ActivityPage = () => {
  const { t } = useTranslation();

  return (
    <Page header={<BreadCrumbs />}>
      <ScrollToTop />
      <h1 className="text-2xl font-semibold text-gray-900 mb-4">
        {t('Activity')}
      </h1>
      <ActivityPatientsList />
    </Page>
  );
};

export default ActivityPage;
