import { serverErrorParser } from "@shared/supabase";
import { Client } from "@shared/types";

export const createDocument = async (
    client: Client,
    payload: { document_url: string; user_id: string }
) => {
    const { data, error } = await client
        .from('documents')
        .insert(payload)
        .select('id')
        .single();

    if (error) {
        return await serverErrorParser(error);
    }

    return data;
};
