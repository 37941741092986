import React from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar } from '@shared/ui';
import { ProfilesUsersPatientsView } from '@entities/patients/model/types';

interface Props {
  seizures: ProfilesUsersPatientsView['seizures'];
}

const SeizureCalendar = ({ seizures }: Props) => {
  const { t } = useTranslation();
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const daysOfWeekShortNames = [
    t('Mn'),
    t('Tu'),
    t('Wd'),
    t('Th'),
    t('Fr'),
    t('St'),
    t('Sn'),
  ];

  return (
    <Calendar
      year={currentYear}
      month={currentMonth}
      data={seizures ?? []}
      daysOfWeekShortNames={daysOfWeekShortNames}
    />
  );
};

export default SeizureCalendar;
