import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDoubleRightIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import { Image } from '@shared/ui';
import { getRoutePatientActivity } from '@shared/const/router';
import { PatientsActivityViewType } from '../model/types';
import { DateUtils } from '@shared/utils';

interface Props {
  patients: PatientsActivityViewType[];
}

const ActivityPatientsTable = ({ patients }: Props) => {
  const { t } = useTranslation();

  return (
    <table className="lg:min-w-full min-w-[900px] divide-y divide-gray-300">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-5 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            {t('Patient')}
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
          >
            {t('Date last PPG')}
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
          >
            {t('Date last Acceleration')}
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
          >
            {t('Date last Seizure')}
          </th>
        </tr>
      </thead>
      <tbody className="divide-y items-center divide-gray-200 bg-white">
        {patients.map((patient, index) => (
          <tr
            key={patient.patient_id}
            className={index % 2 === 0 ? undefined : 'bg-gray-50'}
          >
            <td className="whitespace-nowrap px-5 py-4 text-sm flex items-center text-gray-900">
              <div className="h-10 w-10 flex-shrink-0">
                <Image
                  url={patient.avatar_url || ''}
                  alt="avatar"
                  className="h-10 w-10 rounded-full bg-slate-600"
                />
              </div>
              <div className="ml-4">
                <div>{patient.first_name}</div>
                <div>{patient.second_name}</div>
              </div>
            </td>
            <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-900">
              {patient.latest_ppg_date
                ? DateUtils.format(patient.latest_ppg_date)
                : 'N/A'}
            </td>
            <td className="whitespace-normal text-center px-3 py-4 text-sm text-gray-900">
              {patient.latest_acc_date
                ? DateUtils.format(patient.latest_acc_date)
                : 'N/A'}
            </td>
            <td className="whitespace-normal text-center px-3 py-4 text-sm text-gray-900">
              {patient.latest_seizure_date
                ? DateUtils.format(patient.latest_seizure_date)
                : 'N/A'}
            </td>
            <td className="whitespace-nowrap py-4 px-3">
              <Link to={getRoutePatientActivity(patient.patient_user_id!)}>
                <button
                  type="button"
                  className="w-full rounded-full border flex justify-center shadow-sm py-2 text-sm font-bold text-violet-600 hover:text-violet-800 focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2"
                >
                  <ChevronDoubleRightIcon className="h-5 w-10" />
                </button>
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ActivityPatientsTable;
