import { useMutation } from '@tanstack/react-query';
import { supabase } from '@shared/supabase';
import { InsertMessagesRequest } from '../model/types';
import { insertMessages } from './insert-message';
import { MutationProvider, SupabaseError } from '@shared/types';

const useInsertMessagesMutation: MutationProvider<
  unknown,
  InsertMessagesRequest,
  SupabaseError
> = (options) => {
  return useMutation(
    ['insert-messages'],
    async (payload) => {
      const response = await insertMessages(supabase, payload);
      return response;
    },
    options
  );
};

export default useInsertMessagesMutation;
