import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BreadCrumbs, Button, Preloader, ScrollToTop } from '@shared/ui';
import { Page } from '@shared/layouts';
import { useGetPatientRecordsQuery } from '@entities/activity';
import { useParams } from 'react-router-dom';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { format, parseISO } from 'date-fns';

const PatientActivityPage = () => {
  const { t } = useTranslation();
  const { id: patientUserId } = useParams();
  const [recordType, setRecordType] = useState<'PPG' | 'Accelerations'>('PPG');
  const [intervalDays, setIntervalDays] = useState(1);
  const [chartData, setChartData] = useState<
    { time: string; value: number[] | number }[]
  >([]);

  const { data, isFetching, refetch } = useGetPatientRecordsQuery(
    {},
    {
      input_user_id: patientUserId!,
      interval_days: intervalDays,
      input_record_type: recordType,
    }
  );

  useEffect(() => {
    refetch();
  }, [recordType, intervalDays, refetch]);

  useEffect(() => {
    if (data) {
      const formattedData =
        recordType === 'PPG'
          ? data.map((item) => ({
              time: `${format(parseISO(item.record_time), 'dd/MM')} в ${format(
                parseISO(item.record_time),
                'HH'
              )}`,
              value: Math.round(item.record_value[0]),
            }))
          : data.map((item) => ({
              time: format(parseISO(item.record_time), 'dd:MM HH:mm'),
              value: Math.round(
                Math.sqrt(
                  item.record_value[0] ** 2 +
                    item.record_value[1] ** 2 +
                    item.record_value[2] ** 2
                )
              ),
            }));
      setChartData(formattedData);
    }
  }, [data, recordType]);

  return (
    <Page header={<BreadCrumbs />}>
      <ScrollToTop />
      <h1 className="text-2xl font-semibold text-gray-900 mb-4">
        {t('Activity Schedule')}
      </h1>
      <div className="flex justify-between items-center mb-4">
        <div className="space-x-2">
          {[1, 7, 30].map((days) => (
            <Button
              key={days}
              className={`px-4 py-2 ${
                intervalDays === days ? 'text-white' : 'bg-gray-200'
              }`}
              onClick={() => setIntervalDays(days)}
            >
              {days} {days === 1 ? t('day') : t('days')}
            </Button>
          ))}
        </div>
        <Button
          className="px-4 py-2 text-white"
          onClick={() =>
            setRecordType(recordType === 'PPG' ? 'Accelerations' : 'PPG')
          }
        >
          {t(recordType === 'PPG' ? 'Show Accelerations' : 'Show PPG')}
        </Button>
      </div>
      <div className="p-0">
        <div className="w-full h-[400px] py-8 px-3 bg-white shadow-lg rounded-lg">
          {isFetching ? (
            <div className="pt-20">
              <Preloader size="xl" />
            </div>
          ) : chartData.length > 0 ? (
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time" />
                <YAxis />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="value"
                  stroke="#4f46e5"
                  strokeWidth={2}
                  dot={{ r: 3 }}
                />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <div className="text-center text-gray-500">
              {t('No data available')}
            </div>
          )}
        </div>
      </div>
    </Page>
  );
};

export default PatientActivityPage;
