import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentPlusIcon, DocumentTextIcon } from '@heroicons/react/24/solid';
import Modal from '@shared/ui/modal/modal';
import { Button, FormRhfProvider, InputRhf, SubmitButtonRhf } from '@shared/ui';
import useCreateDocumentMutation from '../api/useCreateDocumentMutation';
import { useUploadDocumentMutation } from '../api/use-upload-document';
import { toast } from 'react-toastify';

interface FormDefaultValuesType {
  file: File | null;
  file_name: string;
}

type UploadDocumentProps = {
  uploadDocumentMessage?: (documentId: number, documentName: string) => void;
  patientId: string;
};

const UploadPatientDocument = ({
  uploadDocumentMessage,
  patientId,
}: UploadDocumentProps) => {
  const defaultValue: FormDefaultValuesType = {
    file: null,
    file_name: '',
  };

  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const uploadFileInputRef = useRef<HTMLInputElement>(null);

  const { mutate, isLoading } = useUploadDocumentMutation();
  const createDocument = useCreateDocumentMutation();

  return (
    <>
      <button
        type="button"
        onClick={() => setIsOpen(true)}
        className="relative block mb-2 w-full border-2 duration-300 border-violet-300 border-dashed rounded-lg p-6 text-center opacity-75 hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <DocumentPlusIcon className="w-14 m-auto font-light text-violet-400" />
        <span className="mt-2 block text-sm font-medium text-violet-900">
          {t('Upload file')}
        </span>
      </button>
      <FormRhfProvider forceFormProvider defaultValues={defaultValue}>
        {({ watch, setValue, reset }) => {
          const selectedFile = watch('file');
          const fileName = watch('file_name');

          const handleFileCreate = (data: FormDefaultValuesType) => {
            if (data.file) {
              mutate(
                {
                  fileName: data.file_name,
                  fileUri: data.file,
                },
                {
                  onSuccess: (uploadData) => {
                    createDocument.mutate(
                      {
                        document_url: uploadData.publicUrl!,
                        user_id: patientId,
                        file_name: data.file_name,
                      },
                      {
                        onSuccess: (createData) => {
                          uploadDocumentMessage?.(
                            createData.id!,
                            data.file_name
                          );
                          toast.success('Вы успешно добавили файл');
                          setIsOpen(false);
                          setTimeout(() => {
                            reset();
                          }, 200);
                        },
                        onError: (error) => {
                          console.error('Error creating document:', error);
                          reset();
                        },
                      }
                    );
                  },
                  onError: (error) => {
                    toast.error('Ошибка загрузки файла');
                    console.error('Error creating document:', error);
                    reset();
                  },
                }
              );
            }
          };

          const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const files = e.target.files!;
            if (files.length > 0) {
              const [inputFile] = files;
              const inputTitle = inputFile?.name.replace(/\.\w{2,10}$/, '');
              setValue('file_name', inputTitle);
              setValue('file', inputFile);
            }
          };

          return (
            <Modal
              isOpen={isOpen}
              onCancel={() => {
                setIsOpen(false);
                setTimeout(() => {
                  setValue('file_name', '');
                  setValue('file', null);
                }, 200);
              }}
              title={t('Select a file to add')}
            >
              <div className="flex flex-col">
                <InputRhf
                  name="file_name"
                  type="text"
                  className="my-5 rounded-lg"
                  required
                />

                {selectedFile && (
                  <div className="flex flex-row justify-center items-center mb-5">
                    <span className="flex items-center text-gray-800 text-lg h-12">
                      {selectedFile.name?.replace(/\.\w{2,10}$/, '')}
                    </span>
                    <DocumentTextIcon className="h-8 w-8" />
                  </div>
                )}

                <div className="flex flex-row space-x-3 md:space-x-10">
                  {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                  <label
                    htmlFor="file"
                    className="w-full"
                    onClick={(e: any) => {
                      if (e.target !== e.currentTarget) e.currentTarget.click();
                    }}
                  >
                    <Button className="w-full bg-violet-500 hover:bg-violet-600">
                      {!selectedFile ? (
                        <span>{t('Select')}</span>
                      ) : (
                        <span>{t('Change')}</span>
                      )}
                    </Button>
                    <InputRhf
                      name="file"
                      id="file"
                      type="file"
                      className="hidden"
                      ref={uploadFileInputRef}
                      onChange={handleFileChange}
                    />
                  </label>
                  <SubmitButtonRhf
                    onSubmit={handleFileCreate}
                    loading={isLoading || createDocument.isLoading}
                    disabled={!selectedFile || !fileName}
                    className="w-full h-9 bg-violet-500 hover:bg-violet-600"
                  >
                    {t('Upload')}
                  </SubmitButtonRhf>
                </div>
              </div>
            </Modal>
          );
        }}
      </FormRhfProvider>
    </>
  );
};

export default UploadPatientDocument;
