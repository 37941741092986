import { useAuth } from "@entities/session";
import { getAllMessages } from "@features/chat/api/get-all-messages-query";
import { DayType, Message } from "@features/chat/model/types";
import { supabase } from "@shared/supabase";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { generateItems } from "..";



export const useChatMessages = (userId: string) => {
    const [messages, setMessages] = useState<(Message | DayType)[]>([]);
    const [offset, setOffset] = useState(0);
    const { user } = useAuth();

    const { data, isLoading } = useQuery<Message[]>({
        queryKey: ['messages', user?.id, userId, offset],
        queryFn: () =>
            getAllMessages({
                client: supabase,
                userId: user!.id,
                chatId: userId,
                offset,
            }),
        enabled: !!user?.id && !!userId,
    });

    useEffect(() => {
        if (data) {
            const newMessages = generateItems(data);
            setMessages((prev) => {
                if (offset === 0) return newMessages; // Первая загрузка
                const uniqueNew = newMessages.filter(
                    (msg) => !prev.some((m) => 'id' in m && m.id === msg.id)
                );
                return [...uniqueNew, ...prev]; // Добавляем старые сверху
            });
        }
    }, [data, offset]);

    return { messages, setMessages, offset, setOffset, isLoading };
}
