import React, { useRef } from 'react';
import MessageList from './chat-message-list';
import { usePatientOnlineStatus } from '@shared/hooks/usePatientOnlineStatus';
import { useAuth } from '@entities/session';
import { Preloader } from '@shared/ui';
import { ChatInputForm } from './chat-input-from';
import { useSubscribeToMessages } from '../utils/hooks/useSubscribeToMessages';
import { useChatMessages } from '../utils/hooks/useChatMessages';
import { useChatScroll } from '../utils/hooks/useChatScroll';
import { ChatHeader } from './chat-header';
import { InsertMessagesRequest } from '../model/types';
import useInsertMessagesMutation from '../api/useInsertMessagesMutation';

type Props = {
  goBack?: () => void;
  withUser: {
    userId: string;
    avatar?: Pick<any, 'url'>;
    name: string;
  };
};

const Chat = ({
  goBack = undefined,
  withUser: { userId, avatar, name },
}: Props) => {
  const { user } = useAuth();
  const showOnline = usePatientOnlineStatus(userId);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);

  const { mutate: insertMessages, isLoading: isSending } =
    useInsertMessagesMutation();

  const { messages, setMessages, offset, setOffset, isLoading } =
    useChatMessages(userId); // get messages

  useSubscribeToMessages(userId, setMessages); // subscribe to real time
  useChatScroll(isLoading, setOffset, chatContainerRef); // scroll control hook

  const handleMessageSend = (
    payload: InsertMessagesRequest,
    reset: () => void
  ) => {
    if (payload && payload.text?.trim()) {
      insertMessages({
        text: payload.text,
        sender_id: user!.id,
        receiver_id: userId,
        document_id: null,
      });
      reset();
    }
  };

  return (
    <div className="flex flex-col h-full">
      <ChatHeader
        goBack={goBack}
        avatar={avatar}
        name={name}
        showOnline={showOnline}
      />
      <div
        ref={chatContainerRef}
        className={`${
          isLoading && offset === 0 && 'flex items-center justify-center'
        } w-full px-6 overflow-y-auto h-[47rem] sm:h-[25rem]`}
      >
        {isLoading && offset > 0 && (
          <div>
            <Preloader size="sm" />
          </div>
        )}
        {isLoading && offset === 0 ? (
          <Preloader size="sm" />
        ) : (
          messages && messages.length > 0 && <MessageList messages={messages} />
        )}
      </div>
      <ChatInputForm
        handleMessageSubmit={handleMessageSend}
        isSending={isSending}
      />
    </div>
  );
};

export default Chat;
