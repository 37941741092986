import { useToggle } from 'react-use';
import { useImmer } from 'use-immer';
import { CreateDoctorProfileResponse, Profile, SignInCodeResponse } from '../model/types';
import { useAuth } from '@entities/session';
import { Session } from '@supabase/supabase-js';
import SignInForm from './sign-in-form';
import SignInCodeForm from './sign-in-code-form';
import SignUpForm from './sign-up-form';

interface LoginFormProps {}

const LoginForm = (props: LoginFormProps) => {
  const { setSession, setUserMetaData } = useAuth();
  const [showSignInCodeForm, toggleSignInCodeForm] = useToggle(false);
  const [showSignUpCodeForm, toggleSignUpCodeForm] = useToggle(false);
  const [formState, setFormState] = useImmer<{
    phone?: string;
    session?: Session;
    profile?: Profile;
  }>({});

  const handleSignInFormSubmit = (phone: string) => {
    setFormState((prevState) => {
      prevState.phone = phone;
    });
    toggleSignInCodeForm();
  };

  const handleSignInCodeFormSubmit = (response: SignInCodeResponse) => {
    if (!response.data.user.user_metadata.is_doctor_registered) {
      setFormState((prevState) => {
        prevState.session = response.data.session;
        prevState.profile = response.profile;
      });
      toggleSignUpCodeForm();
    } else {
      setSession(response.data.session);
    }
  };

  const handleSignUpFormSubmit = (data: CreateDoctorProfileResponse) => {
    if (formState.session) {
      setSession(formState.session);
      setUserMetaData(data.user);
    }
  };

  return (
    <>
      {!showSignInCodeForm && !showSignUpCodeForm && <SignInForm onSubmit={handleSignInFormSubmit} />}
      {showSignInCodeForm && formState.phone && !formState.profile && (
        <SignInCodeForm onSubmit={handleSignInCodeFormSubmit} phone={formState.phone} />
      )}
      {showSignUpCodeForm && formState.profile && (
        <SignUpForm onSubmit={handleSignUpFormSubmit} profile={formState.profile} />
      )}
    </>
  );
};

export default LoginForm;
