import { toast } from "react-toastify";

export const downloadFile = async (fileUrl: string, fileName: string) => {
    try {
        const response = await fetch(fileUrl);
        if (!response.ok) throw new Error('Ошибка при скачивании файла');

        const blob = await response.blob();
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Ошибка скачивания:', error);
        toast.error('Ошибка при скачивании файла');
    }
};