import { toast } from 'react-toastify';
import { Button, ConfirmationModal } from '@shared/ui';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';
import { useRemoveDocumentMutation } from '../api/useRemoveDocumentMutation';
import { TrashIcon } from '@heroicons/react/24/solid';

type RemoveDocumentButtonProps = {
  documentId: number;
};

const RemoveDocumentButton = ({ documentId }: RemoveDocumentButtonProps) => {
  const { t } = useTranslation();
  const [showRemoveModal, toggleRemoveModal] = useToggle(false);
  const { mutate: removeDocument, isLoading } = useRemoveDocumentMutation();

  const handleRemoveDocument = () => {
    removeDocument(documentId, {
      onSuccess: () => {
        toast.success('Вы успешно удалили файл');
        toggleRemoveModal();
      },
      onError: (error) => {
        toast.error(error);
      },
    });
  };

  return (
    <>
      <Button variant="danger" onClick={toggleRemoveModal}>
        <TrashIcon className="h-4 w-4" />
      </Button>
      <ConfirmationModal
        buttonsCenter
        isOpen={showRemoveModal}
        onCancel={toggleRemoveModal}
        cancelText={t('Cancel')}
        onConfirm={handleRemoveDocument}
        confirmText={t('Delete')}
        title={t('File delete confirmation')}
        loading={isLoading}
      >
        {t('Are you sure you want to delete the file?')}
      </ConfirmationModal>
    </>
  );
};

export default RemoveDocumentButton;
