import { Button, ListBody, ListHeader } from '@shared/ui';
import { useTranslation } from 'react-i18next';
import {
  RemoveDocumentButton,
  UpdateDocumentButton,
  UploadPatientDocument,
} from '@features/patient-documents';
import { CloudArrowDownIcon } from '@heroicons/react/24/outline';
import { downloadFile } from './utils/downloadBlobUtils';

type DocumentsListProps = {
  patientUserId: string;
  documents: any[];
};

const DocumentsList = ({ patientUserId, documents }: DocumentsListProps) => {
  const { t } = useTranslation();

  return (
    <div className="mb-4">
      <ListHeader className="mb-2" title={t('Patient files')} />
      <UploadPatientDocument patientId={patientUserId || ''} />
      <div className="rounded-lg  max-h-80 overflow-y-auto scrollbar-hide">
        <ListBody emptyStateText={t('No files found')}>
          {documents.length > 0
            ? documents.map((patientFile) => (
                <div
                  key={patientFile.id}
                  className="flex flex-row items-center border border-violet-200 bg-white rounded-xl shadow p-3 sm:rounded-md"
                >
                  <div className=""> {patientFile.file_name}</div>
                  <div className="flex ml-auto space-x-2">
                    <Button
                      onClick={() =>
                        downloadFile(
                          patientFile.document_url,
                          patientFile.file_name
                        )
                      }
                    >
                      <CloudArrowDownIcon className="h-4 w-4" />
                    </Button>
                    <UpdateDocumentButton
                      fileName={patientFile.file_name || ''}
                      patientFileId={patientFile.id}
                    />
                    <RemoveDocumentButton documentId={patientFile.id} />
                  </div>
                </div>
              ))
            : null}
        </ListBody>
      </div>
    </div>
  );
};

export default DocumentsList;
