import React from 'react';
import { SlideOver } from '@shared/ui';
import { MedicineForm } from './medicine-form/medicine-form';
import { ExtendedUpdatePrescriptionMedicinePayload } from '../model/types';
import {
  formatMedicinePayload,
  generateRandomNumber,
  getMedicineDefaultValues,
} from '../utils/medicineUtils';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { usePrescriptionMedicinesStore } from '@entities/prescription-medicines/store';

interface IMedicineSlideOverFormProps {
  prescriptionMedicineId: number | null;
  onCancel: () => void;
  isOpen: boolean;
}

const LocalMedicineSlideOverForm: React.FC<IMedicineSlideOverFormProps> = ({
  prescriptionMedicineId,
  onCancel,
  isOpen,
}) => {
  const { t } = useTranslation();
  const isNewMedicine = !prescriptionMedicineId;
  const { addMedicine, updateMedicine, medicines } =
    usePrescriptionMedicinesStore();

  const currentMedicine = medicines.find(
    (prescription) => prescription.id === prescriptionMedicineId
  );

  const defaultMedicine = getMedicineDefaultValues(currentMedicine);

  const onFormSubmit = (data: ExtendedUpdatePrescriptionMedicinePayload) => {
    const currentPayload = formatMedicinePayload(data);
    if (isNewMedicine) {
      const randomId = generateRandomNumber();
      addMedicine({ ...currentPayload, id: randomId });
      toast.success(`${t('You have successfully created your medication')}`);
      onCancel();
    } else {
      updateMedicine({ ...currentPayload, id: prescriptionMedicineId });
      toast.success(`${t('You have successfully updated your medication')}`);
      onCancel();
    }
  };

  return (
    <SlideOver isOpen={isOpen} onClose={onCancel}>
      <MedicineForm
        defaultValues={defaultMedicine}
        onSubmit={onFormSubmit}
        isNewMedicine={isNewMedicine}
      />
    </SlideOver>
  );
};

export default LocalMedicineSlideOverForm;
