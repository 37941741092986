import React from 'react';

interface Props {
  day: number;
  divStyle: string;
  textDayStyle: string;
  cb?: () => void;
}

const Day = ({ cb = () => {}, divStyle, day, textDayStyle }: Props) => {
  return (
    <span onClick={cb} className="pt-2">
      <div className={divStyle}>
        <span className={textDayStyle}>{day}</span>
      </div>
    </span>
  );
};

export { Day };
