import { useQuery } from '@tanstack/react-query';
import { ProfilesUsersPatientsView } from '../../model/types';
import { QueryProvider, SupabaseError } from '@shared/types';
import { supabase } from '@shared/supabase';
import { getPatientDetails } from './get-patient-details';

export const useGetPatientDetailsQuery: QueryProvider<
  unknown,
  ProfilesUsersPatientsView,
  SupabaseError,
  string
> = (options, patient_profile_id) => {
  return useQuery(
    ['profiles_users_patients_view', patient_profile_id],
    async () => await getPatientDetails(supabase, patient_profile_id!),
    options
  );
};
