import { FieldErrors, FieldValues, useFormContext, UseFormReturn } from 'react-hook-form';
import { Button, ButtonProps } from '../../button/button';

export type ButtonRhfProps<T extends FieldValues> = Omit<ButtonProps, 'onSubmit'> & {
  onSubmit: (data: T, context: UseFormReturn<T, any>) => void | Promise<void>;
  onError?: (errors: FieldErrors<T>, context: UseFormReturn<T, any>) => void;
};

export function SubmitButtonRhf<TFieldValues extends FieldValues = FieldValues>({
  onSubmit,
  onError,
  ...props
}: ButtonRhfProps<TFieldValues>) {
  const formContext = useFormContext<TFieldValues>();
  const { handleSubmit, formState } = formContext;
  return (
    <Button
      {...props}
      onClick={handleSubmit(
        (data) => {
          onSubmit(data, formContext);
        },
        (errors) => {
          onError?.(errors, formContext);
        }
      )}
    />
  );
}
