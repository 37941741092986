import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/entities/session';
import {
  FormRhfProvider,
  InputRhf,
  PageNotFound,
  Preloader,
  SubmitButtonRhf,
} from '@shared/ui';
import { useGetProfileQuery } from '@entities/profile';
import { UpdateDoctorProfilePayload } from '../model/types';
import useUpdateDoctorProfileMutation from '../api/useUpdateDoctorProfileMutation';
import { toast } from 'react-toastify';
import { UseFormSetValue } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getRoutePatients } from '@shared/const/router';

type Props = {
  renderSlot({
    setValue,
    avatarUrl,
  }: {
    avatarUrl?: string | null;
    setValue: UseFormSetValue<any>;
  }): React.ReactElement;
};

const ProfileDetailEdit = ({ renderSlot }: Props) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();
  const profileData = useGetProfileQuery({}, user?.id);
  const profileUpdateMutation = useUpdateDoctorProfileMutation({
    onSuccess: () => {
      toast.success('Вы успешно обновили профиль');
      navigate(getRoutePatients());
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleProfileEdit = async (payload: UpdateDoctorProfilePayload) => {
    const {
      avatar_url,
      first_name,
      second_name,
      third_name,
      specialization,
      details,
    } = payload;

    const profile = {
      ...profileData.data,
      avatar_url,
      first_name,
      second_name,
      third_name,
    };
    delete profile.doctors;

    const doctor = {
      ...profileData.data?.doctors[0],
      specialization,
      details,
    };

    profileUpdateMutation.mutate({
      profileData: profile,
      doctorData: doctor,
    });
  };

  if (profileData.isLoading) {
    return (
      <div className="pt-20">
        <Preloader size="xl" />
      </div>
    );
  }

  if (profileData.isError) {
    return <PageNotFound />;
  }

  return (
    <FormRhfProvider
      defaultValues={{
        avatar_url: profileData.data.avatar_url,
        first_name: profileData.data.first_name,
        second_name: profileData.data.second_name,
        third_name: profileData.data.third_name,
        specialization: profileData.data.doctors[0].specialization,
        details: profileData.data.doctors[0].details,
      }}
      forceFormProvider
    >
      {({ setValue, watch, formState }) => {
        const isDirty =
          formState.isDirty ||
          watch('avatar_url') !== profileData.data.avatar_url;
        return (
          <div className="xl:grid xl:grid-cols-3 xl:space-x-5 space-y-5 xl:space-y-0">
            <div className="col-span-1 pb-6 grid items-center text-center bg-white rounded-xl shadow">
              {renderSlot({ setValue, avatarUrl: watch('avatar_url') })}
            </div>
            <div className="col-span-2 bg-white p-6 text-center rounded-xl shadow">
              <div className="bg-white">
                <label
                  htmlFor="avatar"
                  className="block text-sm font-medium text-left text-black sm:mt-px sm:pt-2"
                >
                  {t('General information')}
                </label>
                <div className="grid grid-cols-4 gap-6 mt-5">
                  <div className="col-span-4 sm:col-span-2 sm:gap-4 sm:items-start space-y-3 sm:space-y-2">
                    <label className="block text-sm font-medium text-left text-black sm:mt-px sm:pt-2">
                      {t('First Name')}
                    </label>
                    <InputRhf
                      name="first_name"
                      autoFocus
                      placeholder={t('Your first name...')}
                      required
                    />
                  </div>
                  <div className="col-span-4 sm:col-span-2 sm:gap-4 sm:items-start space-y-3 sm:space-y-2">
                    <label className="block text-sm font-medium text-left text-black sm:mt-px sm:pt-2">
                      {t('Second Name')}
                    </label>
                    <InputRhf
                      name="second_name"
                      placeholder={t('Your second name...')}
                      required
                    />
                  </div>
                  <div className="col-span-4 sm:col-span-2 sm:gap-4 sm:items-start space-y-3 sm:space-y-2">
                    <label className="block text-sm font-medium text-left text-black sm:mt-px sm:pt-2">
                      {t('Third Name')}
                    </label>
                    <InputRhf
                      name="third_name"
                      placeholder={t('Your third name...')}
                      required
                    />
                  </div>
                  <div className="col-span-4 sm:col-span-2 sm:gap-4 sm:items-start space-y-3 sm:space-y-2">
                    <label className="block text-sm font-medium text-left text-black sm:mt-px sm:pt-2">
                      {t('Specialization')}
                    </label>
                    <InputRhf
                      name="specialization"
                      placeholder={t('Enter your specialization')}
                      required
                    />
                  </div>
                </div>

                <div className="col-span-4 mt-5 sm:col-span-2 sm:gap-4 sm:items-start space-y-3 sm:space-y-2">
                  <label className="block text-sm font-medium text-left text-black sm:mt-px sm:pt-2">
                    {t('Details')}
                  </label>
                  <InputRhf
                    name="details"
                    className="h-20"
                    multiline
                    placeholder={t('Write a few sentences about yourself.')}
                  />
                </div>
              </div>
              <div className="mt-8 flex justify-end sm:pr-3">
                <SubmitButtonRhf
                  className="w-full sm:w-40"
                  disabled={!isDirty || profileUpdateMutation.isLoading}
                  loading={profileUpdateMutation.isLoading}
                  onSubmit={handleProfileEdit}
                >
                  {t('Save')}
                </SubmitButtonRhf>
              </div>
            </div>
          </div>
        );
      }}
    </FormRhfProvider>
  );
};

export default ProfileDetailEdit;
