import React from 'react';
import { useTranslation } from 'react-i18next';
import { PencilIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { Button, Card } from '@shared/ui';
import { PrescriptionMedicine } from '@entities/prescription-medicines';
import { PrescriptionMedicineResponse } from '@entities/prescription-medicines/model/types';

interface IPatientPrescriptionItemProps {
  id: number;
  description: string;
  medicines: PrescriptionMedicineResponse[];
  createdAt: string | null;
}

const PatientPrescriptionItem = ({
  id,
  description,
  medicines,
  createdAt,
}: IPatientPrescriptionItemProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Card>
      <div className="text-sm text-gray-900">
        <div>
          <div className="flex justify-between">
            <h4 className="text-lg">
              {t('Prescription')}
              {` от ${createdAt ? createdAt : 'N/A'}`}
            </h4>
            <Button
              onClick={() => {
                navigate(`/prescriptions/${id}`);
              }}
            >
              <div className="hidden sm:block mr-1">{t('Change')}</div>
              <PencilIcon className="h-5 w-5" />
            </Button>
          </div>
        </div>
        <p className="mb-2 mt-1">
          {description && `${t('Description')}: ${description}`}
        </p>
        {medicines && medicines.length > 0 ? (
          <>
            <p className="mb-2">{t('Medicines')}:</p>
            {medicines.map((med) => (
              <PrescriptionMedicine key={med.id} prescriptionMedicine={med} />
            ))}
          </>
        ) : (
          <p>{t('No medicines added')}</p>
        )}
      </div>
    </Card>
  );
};

export default PatientPrescriptionItem;
