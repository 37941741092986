import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateDoctorProfile } from './update-doctor-profile';
import {
  UpdateDoctorProfileRequest,
  UpdateDoctorProfileResponse,
} from '../model/types';
import { MutationProvider, SupabaseError } from '@shared/types';
import { supabase } from '@shared/supabase';

const useUpdateDoctorProfileMutation: MutationProvider<
  UpdateDoctorProfileResponse,
  UpdateDoctorProfileRequest,
  SupabaseError
> = (options) => {
  const queryClient = useQueryClient();

  return useMutation(
    ['update-doctor-profile'],
    async ({ doctorData, profileData }) => {
      const response = await updateDoctorProfile(supabase, {
        doctorData,
        profileData,
      });
      await queryClient.invalidateQueries({ queryKey: ['doctor-profile'] });
      return response;
    },
    options
  );
};

export default useUpdateDoctorProfileMutation;
