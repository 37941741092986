import { toast } from 'react-toastify';
import { Button, ConfirmationModal } from '@shared/ui';
import { useTranslation } from 'react-i18next';
import { useRemovePrescriptionMutation } from '../api/useRemovePrescriptionMutation';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

type RemovePrescriptionButtonProps = {
  prescriptionId: number;
};

export const RemovePrescriptionButton = ({
  prescriptionId,
}: RemovePrescriptionButtonProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showRemoveModal, toggleRemoveModal] = useToggle(false);
  const { mutate: removePrescription, isLoading } =
    useRemovePrescriptionMutation();

  const handleRemoveMedicine = () => {
    removePrescription(prescriptionId, {
      onSuccess: () => {
        toast.success('Вы успешно удалили рецепт');
        toggleRemoveModal();
        navigate(-1);
      },
      onError: (error) => {
        toast.error(error);
      },
    });
  };

  return (
    <>
      <Button variant="danger" onClick={toggleRemoveModal}>
        {t('Delete')}
      </Button>
      <ConfirmationModal
        isOpen={showRemoveModal}
        onCancel={toggleRemoveModal}
        onConfirm={handleRemoveMedicine}
        loading={isLoading}
        title={t('Confirm Deletion')}
        confirmText={t('Delete')}
        cancelText={t('Cancel')}
      >
        {t('Are you sure you want to delete this prescription?')}
      </ConfirmationModal>
    </>
  );
};
