import { useMutation } from '@tanstack/react-query';
import { CreateDoctorProfileRequest, CreateDoctorProfileResponse } from '../model/types';
import { createDoctorProfile } from './create-doctor-profile';
import { MutationProvider, SupabaseError } from 'src/shared/types';
import { supabase } from 'src/shared/supabase';

const useCreateDoctorProfileMutation: MutationProvider<
  CreateDoctorProfileResponse,
  CreateDoctorProfileRequest,
  SupabaseError
> = (options) => {
  return useMutation(
    ['create-doctor-profile'],
    async (payload) => {
      const response = await createDoctorProfile(supabase, payload);
      return response;
    },
    options
  );
};

export default useCreateDoctorProfileMutation;
