import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormRhfProvider, InputRhf, SubmitButtonRhf } from 'src/shared/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { FieldValues, UseFormSetError } from 'react-hook-form';
import useSignInMutation from '../api/useSignInMutation';
import { signInFormSchema } from '../model/validators/sign-in-form-schema';

interface SignInFormProps {
  onSubmit: (phone: string) => void;
}

const SignInForm = ({ onSubmit }: SignInFormProps) => {
  const { t } = useTranslation();
  const signInMutation = useSignInMutation();

  async function handleSignInForm({
    payload,
    setError,
  }: {
    payload: FieldValues;
    setError: UseFormSetError<{ email: string; phone: string }>;
  }) {
    await signInMutation.mutateAsync(
      { phone: payload.phone },
      {
        onSuccess: () => {
          onSubmit(payload.phone);
        },
        onError: (error: string) => {
          setError('phone', { message: t(error) });
        },
      }
    );
  }

  return (
    <FormRhfProvider
      resolver={zodResolver(signInFormSchema(t))}
      defaultValues={{
        phone: '',
      }}
      forceFormProvider
    >
      {({ setError }) => (
        <div className="bg-gray-100 h-screen">
          <div className="bg-epihelperBg lg:bg-none min-h-full flex flex-col justify-center py-12 max-w-5xl md:mx-auto">
            <div className="mx-4 lg:max-h-96 lg:flex lg:flex-1 lg:rounded-lg lg:overflow-hidden lg:min-w-[992px] max-w-5xl">
              <div className="lg:bg-epihelper bg-cover w-4/6" />
              <div className="bg-white py-4 px-4 shadow rounded-lg lg:rounded-none lg:w-8/12 md:px-8 md:py-10">
                <h2 className="mb-6 text-left text-2xl font-bold text-gray-900">{t('Sign in to your account')}</h2>
                <div>
                  <div className="mt-4">
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                      {t('Phone number')}:
                    </label>
                    <div className="mt-1">
                      <InputRhf
                        name="phone"
                        defaultValue="+7"
                        autoComplete="cc-number"
                        disabled={signInMutation.isLoading}
                        transformation={(value: string) =>
                          value && value.replace(/[^+0-9]/g, '').replace(/^(7|8)$|^(\d)$/g, '+7$2')
                        }
                        placeholder="+7 (XXX) XXX XX XX"
                        maxLength={12}
                        required
                      />
                    </div>
                  </div>

                  <div className="flex mt-6 gap-3 justify-end">
                    <SubmitButtonRhf
                      onSubmit={(payload) => handleSignInForm({ payload, setError })}
                      loading={signInMutation.isLoading}
                    >
                      {t('Sign In')}
                    </SubmitButtonRhf>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </FormRhfProvider>
  );
};

export default SignInForm;
