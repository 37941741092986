import React from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from '@shared/layouts';
import { ScrollToTop } from '@shared/ui';
import { ProfileDetailEdit } from '@features/profile-edit-form';
import { PhotoLoader } from '@features/upload-image';

const ProfilePage = () => {
  const { t } = useTranslation();

  return (
    <Page>
      <ScrollToTop />
      <h1 className="text-2xl font-semibold text-gray-900 mb-4">
        {t('Profile')}
      </h1>
      <ProfileDetailEdit
        renderSlot={({ avatarUrl, setValue }) => (
          <PhotoLoader setAvatar={setValue} avatarUrl={avatarUrl} />
        )}
      />
    </Page>
  );
};

export default ProfilePage;
