import { serverErrorParser } from '@shared/supabase';
import { Client, CreatePrescriptionPayload } from '../model/types';

export async function createPrescription(
    client: Client,
    { prescription, medicines }: CreatePrescriptionPayload
) {
    const { data, error } = await client.functions.invoke('createPrescription', {
        body: {
            prescription,
            medicines,
        },
    });
    if (error) {
        return await serverErrorParser(error);
    }
    return data;
}
