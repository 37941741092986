import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { cva, VariantProps } from 'class-variance-authority';
import { cn } from '@utils';

const SlideOverVariants = cva(
  'pointer-events-none fixed inset-y-0 flex max-w-full',
  {
    variants: {
      variant: {
        default: 'right-0',
        left: 'left-0',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

type SlideOverProps = VariantProps<typeof SlideOverVariants> & {
  isOpen: boolean;
  onClose: (e: any) => void;
  wrapperClassName?: string;
  title?: string;
  disableCrossIcon?: boolean;
  backdrop?: boolean;
  width?: string;
  panelClassName?: string;
  children: React.ReactNode;
};
const SlideOver = ({
  isOpen,
  onClose,
  variant,
  wrapperClassName,
  title,
  disableCrossIcon,
  panelClassName,
  children,
  backdrop = true,
}: SlideOverProps) => {
  const isLeftVariant = variant === 'left';

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        {backdrop && (
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
        )}
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className={cn(SlideOverVariants({ variant }))}>
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom={
                  !isLeftVariant ? 'translate-x-full' : '-translate-x-full'
                }
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo={
                  !isLeftVariant ? 'translate-x-full' : '-translate-x-full'
                }
              >
                <Dialog.Panel
                  className={cn(
                    'pointer-events-auto w-screen max-w-md',
                    panelClassName
                  )}
                >
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div
                      className={cn(
                        'flex min-h-0 flex-1 overflow-y-auto flex-col py-6 px-4 sm:px-6',
                        wrapperClassName
                      )}
                    >
                      {title && (
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          {title}
                        </Dialog.Title>
                      )}
                      {!disableCrossIcon && (
                        <button
                          type="button"
                          className="absolute z-10 top-4 right-8 rounded-md bg-white text-purple-700 hover:text-purple-500 focus:outline-none "
                          onClick={onClose}
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      )}
                      {children}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SlideOver;
