import React from 'react';
import { useTranslation } from 'react-i18next';
import { cva, VariantProps } from 'class-variance-authority';
import Preloader from '../preloader/preloader';
import cn from 'src/shared/utils/cn';

const ButtonVariants = cva(
  'inline-flex items-center justify-center rounded-md shadow-sm focus:outline-none outline-none disabled:opacity-30',
  {
    variants: {
      variant: {
        default: 'text-white bg-violet-500 active:bg-violet-800',
        outline: 'bg-white border-space-300 border-[1px] active:bg-space-50',
        borderless: 'bg-white text-violet-500 active:text-violet-500 shadow-none',
        danger: 'text-white bg-red-600 active:bg-red-500',
      },
      size: {
        xs: 'px-3 py-1 text-xs gap-x-1.5',
        sm: 'px-3 py-2 text-sm leading-4 gap-x-1.5',
        md: 'px-3 py-2 text-sm leading-5 gap-x-2',
        lg: 'px-4 py-2 text-base gap-x-2.5',
        xl: 'px-5 py-3 text-base gap-x-3',
      },
      hidden: {
        true: 'hidden',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'md',
    },
  }
);

export type ButtonProps = React.ComponentProps<'button'> &
  VariantProps<typeof ButtonVariants> & {
    hidden?: boolean;
    loading?: boolean;
  };

const Button = ({ children, onMouseUp, disabled, hidden, loading, variant, size, ...props }: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <button
      {...props}
      onClick={loading ? undefined : props.onClick}
      onMouseUp={(e) => {
        if (e.button !== 1) return; // Allow only mouse's wheel click
        onMouseUp?.(e);
      }}
      className={cn(ButtonVariants({ variant, size, hidden, className: props.className }))}
      disabled={disabled}
    >
      {children}
      {loading && (
        <Preloader
          className={cn({
            'w-3': size === 'xs',
            'w-3.5': size === 'sm',
            'w-4': ['md', 'lg'].includes(size || ''),
            'w-5': size === 'xl',
            // 'px-3.5': true,
          })}
        />
      )}
    </button>
  );
};

export { Button };
