import React from 'react';
import { Maybe } from 'src/shared/utils/tsutils';
import Preloader from '../preloader/preloader';
import { cn } from '@utils';

type LoaderProps = {
  className?: Maybe<string>;
};

const Loader = ({ className }: LoaderProps) => {
  return <Preloader className={cn('mt-4', className)} size="xl" />;
};

export default Loader;
