import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import { useAuth } from 'src/entities/session';
import { DayType, Message } from '../model/types';
import { dayAgo } from '../utils';
import { useTranslation } from 'react-i18next';

moment.locale('ru');

const MessageItem = ({ message }: { message: Message }) => {
  const { user } = useAuth();
  const loggedInUserMessage = message.sender_id === user?.id;
  return (
    <li
      key={message.id}
      className={
        loggedInUserMessage ? 'flex justify-end' : 'flex justify-start'
      }
    >
      <div
        className={`flex flex-col max-w-full px-2 py-1 text-gray-700 ${
          !loggedInUserMessage ? 'bg-gray-100' : ''
        } rounded shadow`}
      >
        <span className="block break-words mr-2"> {message.text} </span>
        <span className="text-xs text-slate-500 ml-2 self-end">
          {moment(new Date(message.created_at)).format('HH:mm')}
        </span>
      </div>
    </li>
  );
};

interface Props {
  messages: (Message | DayType)[];
}

const MessageList = ({ messages }: Props) => {
  const { t } = useTranslation();
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'instant' });
  }, []);

  return (
    <ul className="space-y-2">
      {messages.map((message: Message | DayType) => {
        if ('type' in message && message?.type && message?.type === 'day') {
          return (
            <div key={message.id} className="flex justify-center mb-2">
              <span className="inline-flex items-center px-2.5 py-1 rounded-full text-base font-medium bg-violet-100 text-gray-800">
                {dayAgo(message.date, t)}
              </span>
            </div>
          );
        }
        return <MessageItem key={message.id} message={message as Message} />;
      })}
      <div ref={messagesEndRef} />
    </ul>
  );
};

export default MessageList;
