import React from 'react';
import { Controller, UseFormResetField } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputRhf } from '@shared/ui';
import SelectIntervalType from './select-interval-type';
import SelectMultipleDays from './select-multiple-days';

interface IntervalSettingsProps {
  control: any;
  intervalType: string;
  resetField: UseFormResetField<any>;
}

export const IntervalSettings: React.FC<IntervalSettingsProps> = ({
  control,
  intervalType,
  resetField,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Controller
        control={control}
        name="interval_type"
        render={({ field: { onChange, value } }) => (
          <div className="flex flex-col">
            <label className="text-sm font-medium mb-1">
              {t('Interval type')}
            </label>
            <SelectIntervalType
              value={value ?? ''}
              onChange={(newValue) => {
                if (newValue !== value) {
                  resetField('interval_value', { defaultValue: [] });
                }
                onChange(newValue);
              }}
            />
          </div>
        )}
      />
      {intervalType === 'DaysOfWeek' && (
        <Controller
          control={control}
          name="interval_value"
          rules={{
            validate: (value) =>
              (value && value.length > 0) ||
              t('You must select at least one day'),
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div>
              <SelectMultipleDays
                selectedDays={value ?? []}
                onChange={onChange}
              />
              {error && (
                <p className="text-red-500 text-sm mt-1">{error.message}</p>
              )}
            </div>
          )}
        />
      )}
      {intervalType === 'CyclicMode' && (
        <>
          <InputRhf
            name="interval_value.0"
            type="number"
            rules={{
              validate: (value) =>
                (value && value > 0) || t('Reception days cannot be negative'),
            }}
            label={t('Reception days')}
            required
          />
          <InputRhf
            name="interval_value.1"
            type="number"
            rules={{
              validate: (value) =>
                (value && value > 0) || t('Break days cannot be negative'),
            }}
            label={t('Break days')}
            required
          />
        </>
      )}
      {intervalType === 'IntervalMode' && (
        <InputRhf
          name="interval_value.0"
          type="number"
          rules={{
            validate: (value) =>
              (value && value > 0) || t('Days interval cannot be negative'),
          }}
          label={t('Days interval')}
          required
        />
      )}
    </>
  );
};
