import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PrescriptionMedicineResponse } from '../model/types';
import { supabase } from '@shared/supabase';
import { updatePrescriptionMedicine } from './update-prescription-medicine';
import { MutationProvider, SupabaseError } from '@shared/types';

const useUpdatePrescriptionMedicineMutation: MutationProvider<
    { id: number },
    PrescriptionMedicineResponse,
    SupabaseError
> = (options) => {
    const queryClient = useQueryClient();
    return useMutation(
        ['update-prescription-medicine'],
        async (payload) => {
            const response = await updatePrescriptionMedicine(supabase, payload);
            await queryClient.invalidateQueries({
                queryKey: ['prescription', response.prescription_id],
            });
            await queryClient.invalidateQueries({
                queryKey: ['prescription-medicine', response.id],
            });
            await queryClient.invalidateQueries({ queryKey: ['medicine-intakes'] });

            return response;
        },
        options
    );
};

export default useUpdatePrescriptionMedicineMutation;
