import React, { Suspense } from 'react';
import cn from '../utils/cn';
import { Loader } from '../ui/loader';

type PageLayoutProps = {
  children: React.ReactNode;
  header?: React.ReactNode;
  className?: string;
};

const Page = ({ children, header, className }: PageLayoutProps) => {
  return (
    <>
      {header}
      <main className="py-3">
        <div className="px-2 sm:px-3 lg:px-0 flex flex-col space-y-3">
          <div className={cn('max-w-5xl w-full self-center', className)}>
            <Suspense fallback={<Loader />}>{children}</Suspense>
          </div>
        </div>
      </main>
    </>
  );
};

export default Page;
