import React from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FormRhfProvider, Preloader, SubmitButtonRhf } from '@shared/ui';
import { toast } from 'react-toastify';
import useUpdatePrescriptionMutation from '../api/useUpdatePrescriptionMutation';
import { PrescriptionWithMedicines } from '../model/types';
import { PrescriptionMedicine } from '@entities/prescription-medicines';
import { useGetPrescriptionQuery } from '@entities/prescriptions';

interface IPrescriptionFormProps {
  prescriptionId: string;
  removePrescriptionButtonSlot: React.ReactNode;
  removePrescriptionMedicineButtonSlot: (medicineId: number) => React.ReactNode;
  toggleMedicineSlideOver: (id: number | null) => void;
}

const PrescriptionForm = ({
  prescriptionId,
  toggleMedicineSlideOver,
  removePrescriptionButtonSlot,
  removePrescriptionMedicineButtonSlot,
}: IPrescriptionFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: prescription, isLoading } = useGetPrescriptionQuery(
    {},
    Number(prescriptionId)
  );
  const prescriptionMedicine = prescription?.prescription_medicines;

  const { mutate: updatePrescription, isLoading: isUpdating } =
    useUpdatePrescriptionMutation();

  const handlePrescriptionFormSubmit = (payload: PrescriptionWithMedicines) => {
    const currentDate = {
      id: payload.id,
      user_id: payload.user_id,
      description: payload.description,
    };
    updatePrescription(currentDate, {
      onSuccess: () => {
        toast.success('Вы успешно обновили рецепт');
        navigate(-1);
      },
      onError: (error) => {
        toast.error(error);
      },
    });
  };

  if (isLoading) {
    return (
      <div className="h-[70vh] col-span-7 items-center flex justify-center">
        <Preloader size="xl" />
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto py-4 sm:px-6 md:px-8">
      <div className="lg:col-span-9 xl:col-span-9 bg-white shadow rounded-lg">
        <div className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-8">
          <FormRhfProvider forceFormProvider defaultValues={prescription}>
            {({ register, formState: { errors } }) => (
              <div className="space-y-8 sm:space-y-12">
                <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
                  <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
                    {t('Update prescription')}
                  </h2>
                </div>
                <div className="text-left">
                  <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-violet-600 focus-within:border-violet-600">
                    <label
                      htmlFor="name"
                      className="absolute -top-3 left-2 -mt-px inline-block px-1 bg-white text-m font-medium text-gray-900"
                    >
                      {t('Description')}
                    </label>
                    <textarea
                      {...register('description', {
                        required: 'Это поле обязательно',
                      })}
                      name="description"
                      id="description"
                      autoComplete="description"
                      rows={4}
                      className="block w-full border-0 p-0 py-2 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                    />
                  </div>
                  <p className="text-red-500 text-sm mt-1">
                    {errors.description?.message}
                  </p>
                  <div className="mx-1 my-3">
                    <button
                      type="button"
                      onClick={() => toggleMedicineSlideOver(null)}
                      className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-emerald-700 bg-emerald-100 hover:bg-emerald-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                    >
                      <PlusIcon className="h-4 w-4" aria-hidden="true" />
                      <p>{t('Add medicine')}</p>
                    </button>
                  </div>
                  {prescriptionMedicine && prescriptionMedicine.length > 0 ? (
                    <div className="my-3 relative">
                      {prescriptionMedicine.map((medicine) => (
                        <PrescriptionMedicine
                          changeMedicine
                          key={medicine.id}
                          prescriptionMedicine={medicine}
                          toggleMedicineSlideOver={() =>
                            toggleMedicineSlideOver(medicine.id)
                          }
                          renderButtonSlot={() =>
                            removePrescriptionMedicineButtonSlot(medicine.id)
                          }
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="my-4 flex-column justify-center">
                      <p className="mt-4 mb-2">
                        {t(
                          'You have not added medicines to this prescription yet'
                        )}
                      </p>
                    </div>
                  )}

                  <div className="pt-5">
                    <div className="flex justify-end space-x-2">
                      {removePrescriptionButtonSlot}
                      <SubmitButtonRhf
                        onSubmit={handlePrescriptionFormSubmit}
                        disabled={isUpdating}
                        loading={isUpdating}
                      >
                        {t('Save')}
                      </SubmitButtonRhf>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </FormRhfProvider>
        </div>
      </div>
    </div>
  );
};

export default PrescriptionForm;
