import { useQuery } from '@tanstack/react-query';
import { Prescriptions } from '../model/types';
import { getPrescriptions } from './get-prescriptions';
import { supabase } from '@shared/supabase';
import { QueryProvider, SupabaseError } from '@shared/types';

export const useGetPrescriptionsQuery: QueryProvider<
  unknown,
  { prescriptions: Prescriptions[]; count: number },
  SupabaseError,
  { pageSize: number; pageNumber: number; doctorId: string }
> = (options, params) => {
  return useQuery(
    ['prescriptions', params?.pageNumber, params?.doctorId],
    async () =>
      await getPrescriptions(supabase, {
        pageSize: params?.pageSize || 10,
        pageNumber: params?.pageNumber || 1,
        doctorId: params?.doctorId || '',
      }),
    options
  );
};
