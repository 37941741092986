import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Day } from './day';
import {
  TDate,
  getArraysWithDaysOfMonth,
  getMonthSeizures,
  nextMonth,
  previousMonth,
} from './utils/utils';

interface Props {
  year: number;
  month: number;
  data: any;
  daysOfWeekShortNames: Array<string>;
}

export type Seizure = Partial<any['seizure']>;

interface IPopover {
  show: boolean;
  seizures?: Array<Seizure>;
}

type TCalendar = TDate & { daysOfMonthes: Array<Array<number>> };

const Calendar = ({
  year: yearProp,
  month: monthProp,
  data: PatientSeizuresData,
  daysOfWeekShortNames,
}: Props) => {
  const { t } = useTranslation();

  const [, setShowPopover] = useState<IPopover>({
    show: false,
  });

  const [date, setCalendar] = useState<TCalendar>({
    year: yearProp,
    month: monthProp,
    daysOfMonthes: getArraysWithDaysOfMonth(yearProp, monthProp),
  });

  const monthNames = [
    t('January'),
    t('February'),
    t('March'),
    t('April'),
    t('May'),
    t('June'),
    t('July'),
    t('August'),
    t('September'),
    t('October'),
    t('November'),
    t('December'),
  ];

  const monthSeizures = getMonthSeizures(PatientSeizuresData, date);

  const [previousMonthDays, currentMonthDays, nextMonthDays] =
    date.daysOfMonthes;

  return (
    <div>
      <div className="bg-white rounded-2xl shadow-md">
        <div className="bg-violet-500 w-full text-white rounded-t-2xl h-10 pt-8 pb-8 flex justify-center items-center">
          <svg
            className="w-8 h-8 m-1 mr-4 cursor-pointer"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              const { year, month } = previousMonth({
                year: date.year,
                month: date.month,
              });
              setCalendar({
                year,
                month,
                daysOfMonthes: getArraysWithDaysOfMonth(year, month),
              });
            }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
            />
          </svg>
          <div id="open-date-input" color="primary" className="w-450">
            <span style={{ fontWeight: 700, fontSize: '20px' }}>
              {monthNames[date.month - 1]} - {date.year}
            </span>
          </div>
          <svg
            className="w-8 h-8 m-1 ml-4 cursor-pointer"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              const { year, month } = nextMonth({
                year: date.year,
                month: date.month,
              });
              setCalendar({
                year,
                month,
                daysOfMonthes: getArraysWithDaysOfMonth(year, month),
              });
            }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <div className="grid grid-cols-7 justify-items-center pt-1 p-5 ">
          {daysOfWeekShortNames.map((dayOfWeek, index) => (
            /* eslint-disable react/no-array-index-key */
            <div key={index} className="p-1 font-bold">
              <p>{dayOfWeek}</p>
            </div>
          ))}
          {previousMonthDays.map((day, index) => {
            return (
              <Day
                key={index}
                divStyle="inline-flex justify-center items-center h-48-px w-48-px text-gray-400"
                textDayStyle="text-base"
                day={day}
              />
            );
          })}
          {currentMonthDays.map((day, index) => {
            const daySeizures = monthSeizures
              .filter((val) => val.day === day)
              .map((val) => val.dataItem.node);
            return daySeizures.length > 0 ? (
              <Day
                key={index}
                divStyle="rounded-full h-8 w-8 flex items-center justify-center text-white bg-violet-500 hover:bg-violet-800 cursor-pointer"
                textDayStyle="text-base"
                day={day}
                cb={() => {
                  setShowPopover({ show: true, seizures: daySeizures });
                }}
              />
            ) : (
              <Day
                key={index}
                divStyle="h-48-px w-48-px"
                textDayStyle="text-base"
                day={day}
              />
            );
          })}
          {nextMonthDays.map((day, index) => {
            return (
              <Day
                key={index}
                divStyle="h-48-px w-48-px text-gray-400"
                textDayStyle="text-base"
                day={day}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
