import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Prescriptions } from '@entities/prescriptions/model/types';
import PrescriptionsTableRow from '../prescriptions-table-row';

interface IPrescriptionsTableProps {
  prescriptions: Prescriptions[];
}

const PrescriptionsTable = ({ prescriptions }: IPrescriptionsTableProps) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('patientId');
  const [patientFilterId, setPatientFilterId] = useState(id);

  return (
    <table className="lg:min-w-full min-w-[900px] divide-y divide-gray-300">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-5 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            {t('Data')}
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            {t('Description')}
          </th>
          <th
            scope="col"
            className="flex px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <div className="pr-3">{t('Patient')}</div>
            <button
              className="font-normal rounded-full px-3 bg-violet-600 hover:bg-violet-800 focus:outline-none text-white"
              onClick={() => setPatientFilterId('')}
            >
              {t('Show All')}
            </button>
          </th>
          <th scope="col" className="relative py-3.5 pl-3 pr-4">
            <span className="sr-only">{t('Edit')}</span>
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {prescriptions.map((prescription, index) => (
          <PrescriptionsTableRow
            isBackgroundGray={index % 2 !== 0}
            key={prescription.prescription_id}
            createdAt={prescription.prescription_created_at}
            firstName={prescription.profile_first_name}
            secondName={prescription.profile_second_name}
            prescriptionId={prescription.prescription_id}
            description={prescription.prescription_description}
            avatarUrl={prescription.profile_avatar_url}
          />
        ))}
      </tbody>
    </table>
  );
};

export default PrescriptionsTable;
