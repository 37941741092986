import { useAuth } from '@entities/session';
import { DayType, Message } from '@features/chat/model/types';
import { supabase } from '@shared/supabase';
import { useEffect } from 'react';

export const useSubscribeToMessages = (
  userId: string,
  setMessages: React.Dispatch<React.SetStateAction<(Message | DayType)[]>>
) => {
  const { user } = useAuth();

  useEffect(() => {
    const channel = supabase
      .channel('custom-all-channel')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'messages',
        },
        async (payload) => {
          try {
            const newMessage = payload.new as Message;
            setMessages((prevMessages) => [...prevMessages, newMessage]);
          } catch (error) {
            console.error('Error processing new message:', error);
          }
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [user?.id, userId]);
};
