import { CloudArrowUpIcon, TrashIcon } from '@heroicons/react/24/solid';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormSetValue } from 'react-hook-form';
import { useUploadAvatarMutation } from '../api/useUploadAvatarMutation';
import { Button, ConfirmationModal, DoctorImage, Preloader } from '@shared/ui';
import { Loader } from '@shared/ui/loader';

interface Props {
  avatarUrl?: string | null;
  setAvatar: UseFormSetValue<any>;
}

const PhotoLoader = ({ avatarUrl, setAvatar }: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showModal, setShowModal] = useState(false);
  const uploadAvatarMutation = useUploadAvatarMutation();

  const handleAvatarChangeClick = () => {
    fileInputRef.current?.click();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files!);

    if (files.length > 0) {
      const fileName = `${Date.now()}`;

      uploadAvatarMutation.mutate(
        { fileName, file: files[0] },
        {
          onSuccess: (data) => {
            setAvatar('avatar_url', data.publicUrl);
            e.target.value = '';
          },
          onError: (error) => {
            console.error(error);
            e.target.value = '';
          },
        }
      );
    }
  };

  const { t } = useTranslation();
  return (
    <div className="sm:grid sm:grid-row-3 sm:gap-4 sm:items-start pt-3">
      <label
        htmlFor="avatar"
        className="block mt-4 xl:mb-10 text-lg xl:text-xl font-medium text-black"
      >
        {t('Avatar')}
      </label>
      <div className="mt-1 sm:mt-0 sm:row-1">
        <div className="flex flex-col items-center">
          {uploadAvatarMutation.isLoading ? (
            <div className="h-48 w-48 xl:h-60 xl:w-60 flex items-center justify-center">
              <Preloader size="xl" />
            </div>
          ) : (
            <DoctorImage
              url={avatarUrl}
              alt="avatar"
              className="h-48 w-48 xl:h-60 xl:w-60 inline-block rounded-full object-cover ring-2 ring-white"
            />
          )}

          <input
            ref={fileInputRef}
            type="file"
            name="avatar"
            className="hidden"
            onChange={handleChange}
          />
          <div className="flex flex-row-2 space-x-4 pt-8 xl:pt-16">
            <Button onClick={handleAvatarChangeClick}>
              <CloudArrowUpIcon className="mr-2 text-white h-4 w-4" />
              {t('Change')}
            </Button>
            {avatarUrl && (
              <Button onClick={() => setShowModal(true)}>
                <TrashIcon className="mr-2 text-white h-4 w-4" />
                {t('Delete')}
              </Button>
            )}
          </div>
        </div>
      </div>
      <ConfirmationModal
        isOpen={showModal}
        cancelText={t('Cancel')}
        confirmText={t('Delete')}
        title={t('Confirm deletion')}
        onCancel={() => setShowModal(false)}
        onConfirm={() => {
          setAvatar('avatar_url', null);
          setShowModal(false);
        }}
      >
        {t('Are you sure you want delete avatar image?')}
      </ConfirmationModal>
    </div>
  );
};

export default PhotoLoader;
