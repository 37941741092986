import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Page } from '@shared/layouts';
import { useToggle } from 'react-use';
import { BreadCrumbs, PageNotFound, ScrollToTop } from '@shared/ui';
import { PrescriptionForm } from '@features/prescriptions-edit-form';
import { MedicineSlideOverForm } from '@features/prescription-medicines';
import { RemovePrescriptionButton } from '@features/prescriptions-edit-form/ui/remove-prescription';
import { RemovePrescriptionMedicineButton } from '@features/remove-prescription-medicines';

interface PrescriptionMedicineEditFormProps {}

const EditPrescriptionPage = ({}: PrescriptionMedicineEditFormProps) => {
  const { id: prescriptionId } = useParams<{ id: string }>();
  const [showMedicineSlideOver, toggleMedicineSlideOver] = useToggle(false);
  const [selectedMedicineId, setSelectedMedicineId] = useState<number | null>(
    null
  );

  if (!prescriptionId) {
    return <PageNotFound />;
  }

  return (
    <Page header={<BreadCrumbs />}>
      <ScrollToTop />
      <MedicineSlideOverForm
        isOpen={showMedicineSlideOver}
        prescriptionId={prescriptionId}
        onCancel={toggleMedicineSlideOver}
        prescriptionMedicineId={selectedMedicineId}
      />
      <PrescriptionForm
        prescriptionId={prescriptionId}
        toggleMedicineSlideOver={(id: number | null) => {
          setSelectedMedicineId(id);
          toggleMedicineSlideOver();
        }}
        removePrescriptionButtonSlot={
          <RemovePrescriptionButton prescriptionId={Number(prescriptionId)} />
        }
        removePrescriptionMedicineButtonSlot={(medicineId) => (
          <RemovePrescriptionMedicineButton
            prescriptionMedicineId={medicineId}
            prescriptionId={Number(prescriptionId)}
          />
        )}
      />
    </Page>
  );
};

export default EditPrescriptionPage;
