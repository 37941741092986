import { createClient } from '@supabase/supabase-js';
import { Database } from './types'; // Импортируйте ваши типы данных, если они есть

// Получаем URL и ключ из переменных окружения
const supabaseUrl = import.meta.env.VITE_SUPABASE_URL || '';
const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY || '';

// Адаптер для хранения сессий в localStorage
const WebStorageAdapter = {
  getItem: (key: string) => Promise.resolve(localStorage.getItem(key)),
  setItem: (key: string, value: string) =>
    Promise.resolve(localStorage.setItem(key, value)),
  removeItem: (key: string) => Promise.resolve(localStorage.removeItem(key)),
};

export const supabase = createClient<Database>(supabaseUrl, supabaseKey, {
  auth: {
    storage: WebStorageAdapter, // Используем WebStorageAdapter
    storageKey: 'supabase-session', // Ключ для хранения сессии в localStorage
    autoRefreshToken: true, // Автоматическая перезагрузка токена
    persistSession: true, // Персистенция сессии
    detectSessionInUrl: true, // Обнаружение сессии в URL (для OAuth, например)
  },
});
