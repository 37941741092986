import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SupabaseError, MutationProvider } from '@shared/types';
import { supabase } from '@shared/supabase';
import { updateDocument } from './update-document';
import { EditDocumentPayload } from '../model/types';

const useUpdateDocumentMutation: MutationProvider<
  { id: number },
  EditDocumentPayload,
  SupabaseError
> = (options) => {
  const queryClient = useQueryClient();
  return useMutation(
    ['update-documents'],
    async (payload) => {
      const response = await updateDocument(supabase, payload);
      await queryClient.invalidateQueries({
        predicate: (query) => {
          const validKeys = ['documents', 'profiles_users_patients_view'];
          return validKeys.includes(`${query.queryKey[0]}`);
        },
      });
      return response;
    },
    options
  );
};

export default useUpdateDocumentMutation;
