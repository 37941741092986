export interface ImageProps {
  url: string;
  className?: string;
  alt?: string;
}

const Image = ({ url, className, alt }: ImageProps) => {
  return <img className={className} src={url} alt={alt} />;
};

export default Image;
