import { create } from 'zustand';
import { User, Session } from '@supabase/supabase-js';
import { queryClient } from 'src/shared/types/query-client';
import { supabase } from '@shared/supabase';

type StateType = {
  user: User | null;
  isLoading: boolean;
  setLoading: (loading: boolean) => void;
  setUserMetaData: (user: User) => void;
  setSession: (session: Session) => void;
  deleteUser: () => void;
  logout: () => void;
};

export const useAuth = create<StateType>()((set) => {
  if (!supabase) {
    throw new Error('Supabase is not initialized yet!');
  } else {
    supabase.auth.onAuthStateChange(async (event, session) => {
      // make sure that cache is clear for the profile query
      set({
        user: session?.user,
        isLoading: false,
      });
    });
  }

  return {
    user: null,
    isLoading: true,

    setLoading: (loading: boolean) => set({ isLoading: loading }),

    setUserMetaData: async (user: User) => {
      set({ isLoading: true });
      if (user) {
        await supabase.auth.updateUser({ data: user.user_metadata });
      }
      set({ user, isLoading: false });
    },

    setSession: async (session: Session) => {
      set({ isLoading: true });
      await supabase.auth.setSession(session);
      set({ isLoading: false });
    },

    logout: async () => {
      set({ isLoading: true });
      await supabase.auth.signOut();
      queryClient.clear();
      set({ isLoading: false });
    },

    deleteUser: async () => {
      set({ isLoading: true });
      await localStorage.deleteItemAsync('supabase-session');
      set({ user: null, isLoading: false });
    },
  };
});
