import { Popover } from '@headlessui/react';
import { ArrowLeftIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Image } from '@shared/ui/index';
import cn from 'src/shared/utils/cn';

interface ChatHeaderProps {
  goBack?: () => void;
  name: string;
  avatar?: Pick<any, 'url'>;
  showOnline: boolean;
}

export const ChatHeader = ({
  goBack,
  name,
  avatar,
  showOnline,
}: ChatHeaderProps) => (
  <div className="relative flex items-center px-3 py-2 border-b border-gray-100">
    {goBack ? (
      <button onClick={goBack} className="mr-2">
        <ArrowLeftIcon className="text-gray-500 h-7 w-7" aria-hidden="true" />
      </button>
    ) : (
      <Popover.Button className="mr-2">
        <XMarkIcon className="text-gray-500 h-7 w-7" aria-hidden="true" />
      </Popover.Button>
    )}

    <Image
      className="object-cover w-8 h-8 rounded-full bg-slate-600"
      url={avatar?.url}
    />
    <span className="block ml-2 font-bold text-gray-600 truncate">{name}</span>
    <span
      className={cn(
        showOnline ? 'bg-green-600' : 'bg-red-600',
        'absolute w-3 h-3 rounded-full border-2 border-white left-[70px] top-[8px]'
      )}
    />
  </div>
);
