import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import {
  BellIcon,
  Bars3BottomLeftIcon,
  HomeIcon,
  ClipboardDocumentListIcon,
  PresentationChartBarIcon,
  PresentationChartLineIcon,
} from '@heroicons/react/24/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { Image, ConfirmationModal, DoctorImage } from '@shared/ui/index';
import { PatientsChatWidget } from '@features/chat/index';
import cn from 'src/shared/utils/cn';
import { useAuth } from 'src/entities/session';
import {
  getRouteActivity,
  getRoutePatients,
  getRoutePrescriptions,
  getRouteProfile,
  getRouteSeizures,
} from '@shared/const/router';
import { useGetProfileQuery } from '@entities/profile';
import { Preloader } from '@shared/ui';

const navigation = [
  { name: 'Patients', href: getRoutePatients(), icon: HomeIcon },
  {
    name: 'Prescriptions',
    href: getRoutePrescriptions(),
    icon: ClipboardDocumentListIcon,
  },
  {
    name: 'Seizures',
    href: getRouteSeizures(),
    icon: PresentationChartBarIcon,
  },
  {
    name: 'Activity',
    href: getRouteActivity(),
    icon: PresentationChartLineIcon,
  },
];
const userNavigation = [{ name: 'View profile', href: getRouteProfile() }];

type Props = {};

const SidebarLayout = ({}: Props) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [Open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { logout, isLoading } = useAuth();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { user } = useAuth();
  const getProfileData = useGetProfileQuery({}, user?.id);

  const toggleSidebarOpen = useCallback(() => {
    setSidebarOpen((prevState) => !prevState);
  }, [setSidebarOpen]);

  useEffect(() => {
    const localSidebar = window.localStorage.getItem('SidebarExpand');
    if (localSidebar != null) setSidebarOpen(JSON.parse(localSidebar));
  }, []);

  useEffect(() => {
    window.localStorage.setItem('SidebarExpand', JSON.stringify(sidebarOpen));
  }, [sidebarOpen]);

  return (
    <>
      <div className="min-h-full">
        <ConfirmationModal
          isOpen={showModal}
          cancelText={t('Cancel')}
          confirmText={t('Logout')}
          title={t('Logout confirmation')}
          onCancel={() => setShowModal(false)}
          loading={isLoading}
          onConfirm={() => {
            logout();
            setShowModal(false);
          }}
        >
          {t('Are you sure you want to logout?')}
        </ConfirmationModal>
        <Transition.Root show={Open} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-20 md:hidden"
            onClose={() => {
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
                <div className="flex-shrink-0 flex items-center px-4">
                  <Image
                    className="h-8 w-auto"
                    url="https://tailwindui.com/img/logos/workflow-logo-indigo-300-mark-white-text.svg"
                    alt="Workflow"
                  />
                </div>
                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                  <nav className="mt-5 flex-1 px-2 space-y-1">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        onClick={() => setOpen(false)}
                        className={cn(
                          pathname.startsWith(item.href)
                            ? 'bg-indigo-50 text-indigo-700'
                            : 'text-base text-gray-500 hover:text-gray-900 hover:bg-gray-300 hover:bg-opacity-75',
                          'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                        )}
                      >
                        <item.icon
                          className="mr-3 flex-shrink-0 h-6 w-6 text-inherit"
                          aria-hidden="true"
                        />
                        <span className="text-gray-900">{t(item.name)}</span>
                      </Link>
                    ))}
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true" />
          </Dialog>
        </Transition.Root>
        <div
          className={`hidden md:flex ${
            sidebarOpen ? `md:w-64` : `md:w-14`
          } duration-300 md:flex-col md:fixed md:inset-y-0 z-20`}
        >
          <div className="flex-1 flex flex-col min-h-0 bg-white py-12">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <nav className="mt-5 flex-1 px-2 space-y-1">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={cn(
                      pathname.startsWith(item.href)
                        ? 'bg-indigo-50 text-indigo-700'
                        : `text-base text-gray-500 hover:text-gray-900 hover:bg-gray-300 hover:bg-opacity-75`,
                      'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                    )}
                  >
                    <item.icon
                      className="mr-3 flex-shrink-0 h-6 w-6 text-inherit"
                      aria-hidden="true"
                    />
                    <span
                      className={`text-gray-900 ${!sidebarOpen && `scale-0`}`}
                    >
                      {t(item.name)}
                    </span>
                  </Link>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <Disclosure
          as="nav"
          className="bg-white border-b border-gray-200 z-30 fixed w-full"
        >
          {() => (
            <>
              <div className="pr-2 pl-1 lg:pr-8 lg:pl-1">
                <div className="flex justify-between h-16">
                  <div className="flex justify-start ">
                    <button
                      type="button"
                      className="visible md:order-2 md:invisible px-4 text-gray-500 mr-3 my-2 hover:text-gray-900 outline-none rounded-md active:ring-offset-0 focus:outline-none hover:bg-gray-300 bg-opacity-75"
                      onClick={() => setOpen(!Open)}
                    >
                      <span className="sr-only">Open sidebar</span>
                      {Open ? (
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      ) : (
                        <Bars3BottomLeftIcon
                          className="h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </button>
                    <button
                      type="button"
                      className="justify-start invisible md:visible px-4 text-gray-500 mr-3 my-2 hover:text-gray-900 outline-none rounded-md active:ring-offset-0 focus:outline-none hover:bg-gray-200 bg-opacity-75"
                      onClick={toggleSidebarOpen}
                    >
                      <span className="sr-only">Open sidebar</span>
                      <Bars3BottomLeftIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                  <div className="ml-3 flex items-center space-x-3">
                    {/* <NotificationDropDown /> */}
                    <Menu as="div" className="relative">
                      <div>
                        <Menu.Button className="bg-white rounded-full h-12 w-12 flex justify-center items-center focus:outline-none focus:ring-offset-2">
                          {getProfileData.isLoading ? (
                            <Preloader className="mr-0" />
                          ) : (
                            <DoctorImage
                              className="h-12 w-12 rounded-full object-cover"
                              url={getProfileData.data?.avatar_url}
                              alt="avatar"
                            />
                          )}
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <Link
                                  to={item.href}
                                  className={cn(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                  )}
                                >
                                  {t(item.name)}
                                </Link>
                              )}
                            </Menu.Item>
                          ))}
                          <Menu.Item>
                            {({ active }) => (
                              <div
                                onClick={() => setShowModal(true)}
                                className={cn(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                                )}
                              >
                                {t('Logout')}
                              </div>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="sm:hidden">
                <div className="pt-2 pb-3 space-y-1">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={cn(
                        pathname.startsWith(item.href)
                          ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
                          : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
                        'block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
                      )}
                      aria-current="page"
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
                <div className="pt-4 pb-3 border-t border-gray-200">
                  <div className="flex items-center px-4">
                    {/* <div className="flex-shrink-0">
                      <img className="h-10 w-10 rounded-full" src={doctor?.user?.avatar?.url} alt="" />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-gray-800">{`${doctor?.user.firstName} ${doctor?.user.secondName}`}</div>
                      <div className="text-sm font-medium text-gray-500">{doctor?.user?.email}</div>
                    </div> */}
                    <button
                      type="button"
                      className="ml-auto bg-white flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <div
          className={`${
            sidebarOpen ? `md:pl-64` : `md:pl-14`
          } duration-300 flex flex-col flex-1`}
        >
          <main className="flex-1">
            <div className="py-24">
              <div className="px-4 sm:px-6 md:px-8">
                <Outlet />
              </div>
            </div>
          </main>
        </div>
        Chat widget
        <PatientsChatWidget />
      </div>
    </>
  );
};

export default SidebarLayout;
