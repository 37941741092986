import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CreatePrescriptionMedicinePayload } from '../model/types';
import { createPrescriptionMedicine } from './create-prescription-medicine';
import { supabase } from '@shared/supabase';
import { MutationProvider, SupabaseError } from '@shared/types';

const useCreatePrescriptionMedicineMutation: MutationProvider<
  unknown,
  CreatePrescriptionMedicinePayload,
  SupabaseError
> = (options) => {
  const queryClient = useQueryClient();
  return useMutation(
    ['create-prescription-medicine'],
    async (payload) => {
      const response = await createPrescriptionMedicine(supabase, payload);
      await queryClient.invalidateQueries({
        queryKey: ['prescription', response.prescription_id],
      });
      await queryClient.invalidateQueries({ queryKey: ['medicine-intakes'] });
      return response;
    },
    options
  );
};

export default useCreatePrescriptionMedicineMutation;
