import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createDocument } from './create-document';
import { DocumentPayload } from '../model/types';
import { supabase } from '@shared/supabase';
import { MutationProvider, SupabaseError } from '@shared/types';

const useCreateDocumentMutation: MutationProvider<
  { id: number },
  DocumentPayload,
  SupabaseError
> = (options) => {
  const queryClient = useQueryClient();
  return useMutation(
    ['create-document'],
    async (payload) => {
      const response = await createDocument(supabase, payload);
      await queryClient.invalidateQueries({
        predicate: (query) => {
          const validKeys = ['documents', 'profiles_users_patients_view'];
          return validKeys.includes(`${query.queryKey[0]}`);
        },
      });
      return response;
    },
    options
  );
};

export default useCreateDocumentMutation;
