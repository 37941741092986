import React from 'react';
import { doctorPlaceholder } from '@assets/index';

export interface Props {
  url?: string | null;
  className?: string;
  alt?: string;
}

const DoctorImage = ({ url, className, alt }: Props) => {
  return <img className={className} src={url || doctorPlaceholder} alt={alt} />;
};

export default DoctorImage;
