import React from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Card } from '@shared/ui/card';
import { ListBody, ListHeader } from '@shared/ui';
import { ProfilesUsersPatientsView } from '@entities/patients/model/types';

interface PatientSeizures {
  patientSeizures: ProfilesUsersPatientsView['seizures'];
}

const PatientSeizureList = ({ patientSeizures }: PatientSeizures) => {
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <>
      <ListHeader
        className="mb-2"
        title={t('Seizures')}
        actionButton={
          patientSeizures.length > 3 && (
            <Link
              to={`/seizures/${id}`}
              className="px-4 py-2
              bg-white border-gray-300 ring-2 ring-gray-300
              text-sm font-medium text-gray-700 text-center
              rounded-lg md:rounded-xl sm:rounded-md
              hover:bg-gray-100
              focus:ring-2 focus:ring-gray-300 focus:bg-gray-100 focus:text-black"
            >
              {`${t('View all')} (${patientSeizures.length})`}
            </Link>
          )
        }
      />
      <ListBody emptyStateText={t('This patient has no seizures yet')}>
        {patientSeizures.length > 1 ? (
          // limit the number of seizures displayed with slice
          patientSeizures.slice(0, 5).map((seizure) => (
            <Card key={seizure.id}>
              <div className="grid grid-col-2 space-y-3 justify-between">
                <div className="text-sm font-medium">
                  {`${t('Date')}:  ${format(
                    new Date(seizure.date || ''),
                    `dd/MM/yyyy`
                  )}`}
                  {`${format(
                    new Date(seizure.date || ''),
                    ` - ${t('Time')}: hh:mm:ss`
                  )}`}
                </div>
                <div className="text-sm text-gray-600">
                  {`${t(seizure.intensity!)}: ${format(
                    new Date(seizure.duration || ''),
                    `HH:MM`
                  )}`}
                </div>
              </div>
            </Card>
          ))
        ) : (
          <div className="pt-4 text-center">Список пуст</div>
        )}
      </ListBody>
    </>
  );
};

export default PatientSeizureList;
