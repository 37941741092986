import React, { HTMLProps, useId, useLayoutEffect, useRef } from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import { FormContainerBaseTypes } from './types';
import { cn } from '@utils';

const CheckboxVariants = cva('h-4 w-4 rounded cursor-pointer', {
  variants: {
    variant: {
      default: 'border-gray-300 text-violet-500 focus:ring-violet-500',
      danger: 'border-red-300 bg-red-50 text-red-600 focus:ring-red-600',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});
export type CheckboxProps = Omit<HTMLProps<HTMLInputElement>, 'type'> &
  VariantProps<typeof CheckboxVariants> &
  FormContainerBaseTypes;

export const Checkbox = ({ label, required, variant, indeterminate, ...props }: CheckboxProps) => {
  const randomId = useId();
  const inputRef = useRef<HTMLInputElement>(null);
  useLayoutEffect(() => {
    if (inputRef.current && indeterminate) {
      inputRef.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);

  return (
    <div className="relative flex items-start">
      <div className="flex h-6 items-center">
        <input
          id={randomId}
          ref={inputRef}
          {...props}
          required={required}
          type="checkbox"
          className={cn(CheckboxVariants({ variant, className: props.className }))}
        />
      </div>
      <div className="ml-3 text-sm leading-6 ">
        <label htmlFor={randomId} className="text-gray-500 cursor-pointer select-none">
          {label}
          {required && <span className="text-red-500"> *</span>}
        </label>
      </div>
    </div>
  );
};
