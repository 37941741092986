import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { PageNotFound, Preloader } from '@shared/ui';
import { useTranslation } from 'react-i18next';
import {
  SeizureCalendar,
  PatientSeizureList,
  PatientPrescriptionsList,
  PatientInfo,
  useGetPatientDetailsQuery,
} from '@entities/patients';
import DocumentsList from '@widgets/documents-list/documents-list';

const PatientDetailsPage = () => {
  const { id: patientUserId } = useParams();
  const { t } = useTranslation();

  const {
    data: patient,
    isLoading,
    isError,
  } = useGetPatientDetailsQuery({}, patientUserId);

  const documents = useMemo(() => {
    const docs = patient?.documents || [];
    return [...docs].sort((a, b) => b.id - a.id);
  }, [patient?.documents]);

  if (isLoading) {
    return (
      <div className="pt-20">
        <Preloader size="xl" />
      </div>
    );
  }

  if (!patient || isError) {
    return <PageNotFound />;
  }

  return (
    <div>
      <h2 className="text-2xl font-medium text-gray-900">
        {t('Patient card')}
      </h2>
      <div className="mt-8 xl:grid xl:grid-cols-3 xl:grid-row-3 gap-4 space-y-5 xl:space-y-0">
        <div className="xl:col-span-2 xl:row-span-2">
          <PatientInfo patient={patient} />
        </div>
        <div className="xl:col-span-1 xl:row-span-1">
          <SeizureCalendar seizures={patient.seizures} />
        </div>
        <div className="xl:col-span-1 xl:row-span-3">
          <PatientSeizureList patientSeizures={patient.seizures} />
        </div>
        <div className="xl:col-span-2 xl:row-span-2">
          <DocumentsList patientUserId={patientUserId!} documents={documents} />
          <PatientPrescriptionsList
            patientPrescriptions={patient.prescriptions}
            patientUserId={patientUserId}
          />
        </div>
      </div>
    </div>
  );
};

export default PatientDetailsPage;
