import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import { QueryBoundaries } from 'src/shared/infrastructure/query-boundaries';
import { queryClient } from 'src/shared/types/query-client';
import { QueryClientProvider } from '@tanstack/react-query';
/* Styles */
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/tailwind.css';
import './styles/index.css';
import './i18n/i18n';

const rootElement = document.getElementById('root')!;
const root = createRoot(rootElement);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <QueryBoundaries>
        <App />
      </QueryBoundaries>
    </QueryClientProvider>
  </React.StrictMode>
);
