import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import React, { Fragment } from 'react';
import cn from 'src/shared/utils/cn';

export interface IModalProps {
  icon?: string;
  title?: string;
  onCancel: () => void;
  isOpen: boolean;
  children: React.ReactNode;
  className?: string;
}

const Modal = ({
  children,
  icon,
  title,
  onCancel,
  isOpen,
  className,
}: IModalProps) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-30 inset-0 overflow-y-auto"
        onClose={onCancel}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={cn(
                'relative border-violet-600 border-[1px] inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl w-full sm:p-6 sm:px-12',
                className
              )}
            >
              <div>
                <div className="relative">
                  <XMarkIcon
                    onClick={onCancel}
                    className="absolute cursor-pointer right-0 top-0 text-primary w-6"
                  />
                  <div className="flex items-center">
                    {title && (
                      <>
                        {icon && (
                          <img
                            src={icon}
                            alt="info-sign"
                            className="w-8 mr-4"
                          />
                        )}
                        <div className="text-xl text-primary leading-6 uppercase font-bold">
                          {title}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
