import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PatientSeizuresTable from '@entities/seizures/ui/seizures-table';
import {
  NoElementFound,
  PageNotFound,
  PaginationList,
  Preloader,
} from '@shared/ui';
import { useGetPatientSeizuresQuery } from '@entities/seizures/api/useGetPatientSeizuresQuery';

const itemsOnPage = 10;

type SeizuresProps = {
  patientUserId: string | null;
};
const SeizureList = ({ patientUserId }: SeizuresProps) => {
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);
  const { data, isError, isLoading } = useGetPatientSeizuresQuery(
    {},
    { pageSize: itemsOnPage, pageNumber: pageNumber, patientId: patientUserId! }
  );

  if (!patientUserId) {
    return <PageNotFound />;
  }

  const totalCount: number | undefined = data?.count;

  if (isLoading) {
    return (
      <div className="pt-20">
        <Preloader size="xl" />
      </div>
    );
  }

  if (totalCount === 0) {
    return <NoElementFound title={t('No records found')} />;
  }

  if (isError) {
    return <PageNotFound />;
  }

  return (
    <div className="pt-4">
      <div className="flex flex-col pb-4">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle px-1 md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
              <PatientSeizuresTable patientSeizures={data.seizuresData} />
            </div>
          </div>
        </div>
      </div>
      {totalCount && (
        <PaginationList
          itemsOnPage={itemsOnPage}
          totalCount={totalCount}
          selectedPage={pageNumber}
          onPageSelect={setPageNumber}
        />
      )}
    </div>
  );
};

export default SeizureList;
