import React, { memo } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { RequireAuth } from './require-auth';
import {
  routeConfig,
  routeConfigAuthOnly,
  routeConfigNoAuthOnly,
  routeConfigPublic,
} from '../config/route-config';
import { RootLayout } from '@shared/layouts';
import { QueryBoundaries } from '@shared/infrastructure/query-boundaries';
import { useAuth } from 'src/entities/session';
import Sidebar from '@widgets/sidebar';
import { getRouteAuth } from '@shared/const/router';

const AppRouter = () => {
  const { user, isLoading } = useAuth();

  const routesForPublic = Object.values(routeConfigPublic);
  const routeForNoAuthOnly = Object.values(routeConfigNoAuthOnly);

  const routes = [
    {
      element: <RootLayout />,
      ErrorBoundary: QueryBoundaries,
      children: [
        {
          path: getRouteAuth(),
          element: routeConfig.auth.element,
          isLoading: isLoading,
        },
        ...[
          ...Object.values(routeConfigAuthOnly),
          ...(user ? routesForPublic : []),
        ].map((route) => ({
          path: route.path,
          element: (
            <RequireAuth>
              <Sidebar />
            </RequireAuth>
          ),
          children: [
            {
              path: route.path,
              element: route.element,
            },
          ],
        })),

        ...(!user
          ? routeForNoAuthOnly.map((route) => ({
              path: route.path,
              element: route.element,
            }))
          : []),
        ...routesForPublic.map((route) => ({
          path: route.path,
          element: route.element,
        })),
      ],
    },
  ];

  const router = createBrowserRouter(routes);
  return <RouterProvider router={router} />;
};

export default memo(AppRouter);
