import { useQuery } from '@tanstack/react-query';
import { Seizure } from '../model/types';
import { getPatientSeizures } from './get-patient-seizures';
import { supabase } from '@shared/supabase';
import { QueryProvider, SupabaseError } from '@shared/types';

export const useGetPatientSeizuresQuery: QueryProvider<
  unknown,
  { seizuresData: Seizure[]; count: number },
  SupabaseError,
  { patientId: string; pageSize: number; pageNumber: number }
> = (options, params) => {
  return useQuery(
    ['seizures', params?.patientId, params?.pageNumber],
    async () =>
      await getPatientSeizures(supabase, {
        patientId: params?.patientId!,
        pageSize: params?.pageSize || 10,
        pageNumber: params?.pageNumber || 1,
      }),
    options
  );
};
