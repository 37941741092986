import { User } from '@supabase/supabase-js';
import { Client } from '../model/types';
import { serverErrorParser } from '@shared/supabase';

export async function getPrescriptions(
  client: Client,
  {
    pageSize,
    doctorId,
    pageNumber,
  }: { pageSize: number; pageNumber: number; doctorId: User['id'] }
) {
  let from = (pageNumber - 1) * pageSize;
  let to = from + pageSize - 1;

  const { data, count, error } = await client
    .from('prescription_patient_profile_view')
    .select('*', { count: 'exact' })
    .eq('doctor_id', doctorId)
    .range(from, to)
    .order('prescription_created_at', { ascending: false })
    .throwOnError();

  if (error) {
    return await serverErrorParser(error);
  }

  return { prescriptions: data, count };
}
