import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormRhfProvider, InputRhf, SubmitButtonRhf } from 'src/shared/ui';
import { useAuth } from 'src/entities/session';
import { UseFormSetError } from 'react-hook-form';
import useSignInCodeMutation from '../api/useSignInCodeMutation';
import { SignInCodeResponse } from '../model/types';

interface SignInCodeFormProps {
  onSubmit: (data: SignInCodeResponse) => void;
  phone: string;
}

const SignInCodeForm = ({ onSubmit, phone }: SignInCodeFormProps) => {
  const { t } = useTranslation();
  const { isLoading } = useAuth();
  const verifyOTPMutation = useSignInCodeMutation();

  async function signInWithCode({
    data,
    setError,
  }: {
    data: { code: string };
    setError: UseFormSetError<{ code: string }>;
  }) {
    await verifyOTPMutation.mutateAsync(
      {
        phone: phone?.trim()!,
        code: data.code,
      },
      {
        onSuccess: (data) => {
          onSubmit(data);
        },
        onError: (error) => {
          setError('code', { message: t(error) });
        },
      }
    );
  }

  return (
    <FormRhfProvider
      defaultValues={{
        code: '',
      }}
      forceFormProvider
    >
      {({ setError }) => (
        <div className="bg-gray-100 h-screen">
          <div className="bg-epihelperBg lg:bg-none min-h-full flex flex-col justify-center py-12 max-w-5xl md:mx-auto">
            <div className="mx-4 lg:max-h-96 lg:flex lg:flex-1 lg:rounded-lg lg:overflow-hidden lg:min-w-[992px] max-w-5xl">
              <div className="lg:bg-epihelper bg-cover w-4/6" />
              <div className="bg-white py-4 px-4 shadow rounded-lg lg:rounded-none lg:w-8/12 md:px-8 md:py-10">
                <h2 className="mb-6 text-left text-2xl font-bold text-gray-900">{t('Authorization code sent')}</h2>
                <div>
                  <div className="mt-4">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      {t('Code')}:
                    </label>
                    <div className="mt-1">
                      <InputRhf
                        name="code"
                        autoFocus
                        autoComplete="one-time-code"
                        transformation={(value: string) => value && value.replace(/[^+0-9]/g, '')}
                        placeholder="XXXX"
                        type="tel"
                        maxLength={4}
                        required
                      />
                    </div>
                  </div>

                  <div className="flex mt-6 gap-3 justify-end">
                    <SubmitButtonRhf
                      loading={verifyOTPMutation.isLoading || isLoading}
                      onSubmit={(data) => {
                        signInWithCode({ data: data as { code: string }, setError });
                      }}
                    >
                      {t('Next')}
                    </SubmitButtonRhf>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </FormRhfProvider>
  );
};

export default SignInCodeForm;
