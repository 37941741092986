import { useMutation } from '@tanstack/react-query';
import { SignInCodeRequest, SignInCodeResponse } from '../model/types';
import { MutationProvider, SupabaseError } from 'src/shared/types';
import { serverErrorParser, supabase } from 'src/shared/supabase';

const useSignInCodeMutation: MutationProvider<SignInCodeResponse, SignInCodeRequest, SupabaseError> = () => {
  return useMutation(async (payload) => {
    const { data, error } = await supabase.functions.invoke('verifyDoctorOtp', {
      body: {
        phone: payload.phone,
        code: payload.code,
      },
    });
    if (error) {
      return await serverErrorParser(error);
    }
    return data;
  });
};

export default useSignInCodeMutation;
