import React, { useState } from 'react';
import { Page } from '@shared/layouts';
import { useToggle } from 'react-use';
import { useParams } from 'react-router';
import { BreadCrumbs, ScrollToTop } from '@shared/ui';
import { LocalPrescriptionForm } from '@features/prescriptions-edit-form';
import { LocalMedicineSlideOverForm } from '@features/prescription-medicines';
import { LocalRemovePrescriptionMedicineButton } from '@features/remove-prescription-medicines';

interface PrescriptionMedicineEditFormProps {}

const NewPrescriptionPage = ({}: PrescriptionMedicineEditFormProps) => {
  const { id: userId } = useParams<{ id: string }>();
  const [showMedicineSlideOver, toggleMedicineSlideOver] = useToggle(false);
  const [selectedMedicineId, setSelectedMedicineId] = useState<number | null>(
    null
  );

  return (
    <Page header={<BreadCrumbs />}>
      <ScrollToTop />
      <LocalMedicineSlideOverForm
        isOpen={showMedicineSlideOver}
        onCancel={toggleMedicineSlideOver}
        prescriptionMedicineId={selectedMedicineId}
      />
      <LocalPrescriptionForm
        userId={userId}
        toggleMedicineSlideOver={(id: number | null) => {
          setSelectedMedicineId(id);
          toggleMedicineSlideOver();
        }}
        removePrescriptionMedicineButtonSlot={(medicineId) => (
          <LocalRemovePrescriptionMedicineButton
            prescriptionMedicineId={medicineId}
          />
        )}
      />
    </Page>
  );
};

export default NewPrescriptionPage;
