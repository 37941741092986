import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  NoElementFound,
  PageNotFound,
  PaginationList,
  Preloader,
} from '@shared/ui';
import PatientCard from './patient-card';
import { useGetDoctorPatientsQuery } from '../api/doctor-patients/useGetDoctorPatientsQuery';
import { useAuth } from '@entities/session';

const itemsOnPage = 6;

const PatientList = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const { user } = useAuth();
  const { data, isLoading, isError } = useGetDoctorPatientsQuery({}, user?.id);

  const { t } = useTranslation();

  const patients = useMemo(() => {
    return (
      data?.map((patient) => ({
        id: patient.user_id!,
        name: `${patient.first_name}  ${patient.second_name}`,
        imageUrl: patient.avatar_url!,
      })) || []
    );
  }, [data]);

  if (isLoading) {
    return (
      <div className="pt-20">
        <Preloader size="xl" />
      </div>
    );
  }

  if (patients.length === 0) {
    return <NoElementFound title={t('Patients list is empty')} />;
  }

  if (isError) {
    return <PageNotFound />;
  }

  const totalCount: number | undefined = data?.length;

  return (
    <div className="lg:col-span-9 xl:col-span-9">
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-3">
        {patients.map((person) => (
          <PatientCard key={person.id} {...person} />
        ))}
      </ul>
      {totalCount && (
        <div className="mt-4">
          <PaginationList
            itemsOnPage={itemsOnPage}
            totalCount={totalCount}
            selectedPage={pageNumber}
            onPageSelect={setPageNumber}
          />
        </div>
      )}
    </div>
  );
};

export default PatientList;
