import { User } from '@supabase/supabase-js';
import { Client } from '../model/types';
import { serverErrorParser } from '@shared/supabase';

export async function getProfile(client: Client, user_id: User['id']) {
  const { data, error } = await client
    .from('profiles')
    .select('*, doctors(*)')
    .eq('user_id', user_id!)
    .limit(1)
    .maybeSingle()
    .throwOnError();

  if (error) {
    return await serverErrorParser(error);
  }
  return data;
}
