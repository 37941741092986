import { serverErrorParser } from '@shared/supabase';
import { UploadDocumentRequest, UploadDocumentResponse } from '../model/types';
import { Client } from '@shared/types';


// Для обработки названий на кириллице
const sanitizeFileName = (fileName: string) => {
    return fileName
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "") // Убираем диакритические знаки
        .replace(/\s+/g, "_") // Пробелы → `_`
        .replace(/[^a-zA-Z0-9._-]/g, ""); // Убираем запрещенные символы
};

export default async function uploadDocument(
    client: Client,
    { fileUri, fileName }: UploadDocumentRequest
): Promise<UploadDocumentResponse> {
    const safeFileName = `${Date.now()}-${(sanitizeFileName(fileName))}`;

    const { data, error } = await client.storage
        .from('documents')
        .upload(safeFileName, fileUri)

    if (error) {
        return await serverErrorParser(error);
    }

    const {
        data: { publicUrl },
    } = client.storage.from('documents').getPublicUrl(data?.path);
    return { ...data, publicUrl };
}
