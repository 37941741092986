import React from 'react';
import { useTranslation } from 'react-i18next';

const intervalOptions = [
  { value: 'IntervalMode', label: 'IntervalMode' },
  { value: 'CyclicMode', label: 'CyclicMode' },
  { value: 'DaysOfWeek', label: 'DaysOfWeek' },
];

interface SelectIntervalTypeProps {
  value: string;
  onChange: (value: string) => void;
}

const SelectIntervalType = ({ value, onChange }: SelectIntervalTypeProps) => {
  const { t } = useTranslation();
  return (
    <select
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="p-2 border-gray-300 rounded-md w-full focus:ring-1 focus:ring-purple-500 
      hover:border-gray-500 transition-colors"
    >
      {intervalOptions.map((option) => (
        <option key={option.value} value={option.value}>
          {t(option.label)}
        </option>
      ))}
    </select>
  );
};

export default SelectIntervalType;
