import { Button, SubmitButtonRhf, InputRhf, FormRhfProvider } from '@shared/ui';
import { useTranslation } from 'react-i18next';
import useUpdateDocumentMutation from '../api/useUpdateDocumentMutation';
import { toast } from 'react-toastify';
import { useToggle } from 'react-use';
import { useRef } from 'react';
import { PencilIcon } from '@heroicons/react/24/solid';
import Modal from '@shared/ui/modal/modal';

interface updateDocumentButtonProps {
  fileName: string;
  patientFileId: number;
}

const UpdateDocumentButton = ({
  fileName,
  patientFileId,
}: updateDocumentButtonProps) => {
  const { t } = useTranslation();
  const [showUpdateModal, toggleUpdateModal] = useToggle(false);
  const uploadFileInputRef = useRef<HTMLInputElement>(null);
  const { mutate, isLoading } = useUpdateDocumentMutation();

  const handleFileUpdate = (data: { file_name: string }) => {
    const currentDate = {
      id: patientFileId,
      file_name: data.file_name,
    };
    mutate(currentDate, {
      onSuccess: () => {
        toast.success('Вы успешно обновили файл');
        toggleUpdateModal();
      },
      onError: (error) => {
        toast.error(error);
      },
    });
  };
  return (
    <FormRhfProvider
      forceFormProvider
      defaultValues={{
        file_name: fileName,
      }}
    >
      {({ watch, setValue }) => {
        const fileName = watch('file_name');
        return (
          <>
            <Button
              onClick={() => {
                toggleUpdateModal();
                setValue('file_name', fileName);
              }}
            >
              <PencilIcon className="h-4 w-4" />
            </Button>
            <Modal
              isOpen={showUpdateModal}
              onCancel={toggleUpdateModal}
              title={t('Edit file name')}
            >
              <div className="flex flex-col mt-4">
                <InputRhf className="mb-5 rounded-lg" name="file_name" />
                <div className="flex flex-row space-x-10">
                  <Button className="w-full" onClick={toggleUpdateModal}>
                    {t('Cancel')}
                  </Button>
                  <input
                    type="file"
                    id="file-upload"
                    name="file-upload"
                    className="hidden"
                    ref={uploadFileInputRef}
                  />
                  <SubmitButtonRhf
                    loading={isLoading}
                    className="w-full bg-violet-600 hover:bg-violet-800"
                    disabled={!fileName}
                    onSubmit={handleFileUpdate}
                  >
                    <span>{t('Update')}</span>
                  </SubmitButtonRhf>
                </div>
              </div>
            </Modal>
          </>
        );
      }}
    </FormRhfProvider>
  );
};

export default UpdateDocumentButton;
