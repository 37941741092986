import { FunctionsHttpError } from '@supabase/supabase-js';

export default async function serverErrorParser(error: any) {
  let errorMessage = error.message;
  if (error instanceof FunctionsHttpError) {
    errorMessage = (await error.context.json());
    // msg - supabase system errors (missing JWT and etc.)
    // message - all other 500 server errors
    errorMessage = errorMessage.msg || errorMessage.message;
    if (typeof errorMessage !== 'string') {
      errorMessage = `No error message from server: ${error?.context?.status}`;
    } else {
      errorMessage = `Server error (${error?.context?.status}): ${errorMessage}`;
    }
  }
  return Promise.reject(errorMessage);
}