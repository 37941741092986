// @ts-nocheck
import { DateUtils } from '@shared/utils';
import {
  ExtendedUpdatePrescriptionMedicinePayload,
  PrescriptionMedicine,
  PrescriptionMedicineResponse,
} from '../model/types';

export const getMedicineDefaultValues = (
  medicine?: PrescriptionMedicineResponse
) => {
  if (!medicine) {
    return {
      medicine_name: '',
      interval_type: 'IntervalMode' as const,
      interval_value: [''],
      start_date: new Date().toISOString().split('T')[0],
      treatment_period: '',
      dosage: '',
      intake_times: [{}],
      remind: false,
    } as unknown as PrescriptionMedicineResponse;
  }

  return {
    ...medicine,
    start_date: DateUtils.toFilterFormat(
      medicine?.start_date || new Date().toISOString().split('T')[0]
    ),
    intake_times: medicine?.intake_times?.map((time) => ({
      intake_time: DateUtils.format(time, 'HH:mm'),
    })),
  } as unknown as PrescriptionMedicineResponse;
};

export const formatMedicinePayload = (
  payload: ExtendedUpdatePrescriptionMedicinePayload
): PrescriptionMedicine => ({
  ...payload,
  intake_times: payload.intake_times?.map((time) =>
    DateUtils.formatTimeToISO(time.intake_time)
  ),
  start_date: DateUtils.startOfDay(payload.start_date),
});

export const generateRandomNumber = () => {
  const min = 1000000;
  const max = 10 ** 16 - 1;
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
