import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, memo, useState } from 'react';
import { HomeIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Outlet, useOutletContext } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { QueryBoundaries } from '../infrastructure/query-boundaries';

type SidebarLayoutProps = {
  sidebar?: React.ReactNode;
};

const navigation = [{ name: 'Companies', href: '#', icon: HomeIcon, current: true }];
const userNavigation = [
  { name: 'Your profile', href: '#' },
  { name: 'Sign out', href: '#' },
];

type ContextType = [boolean, React.Dispatch<React.SetStateAction<boolean>>];

const SidebarLayout = ({ sidebar }: SidebarLayoutProps) => {
  const { t } = useTranslation();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">{t('Close sidebar')}</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                {sidebar}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="absolute -z-50 invisible lg:visible lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        {sidebar}
      </div>

      <div className="lg:pl-72">
        <QueryBoundaries>
          <Outlet context={[sidebarOpen, setSidebarOpen]} />
        </QueryBoundaries>
      </div>
    </div>
  );
};

export default memo(SidebarLayout);

export function useSidebarOpen() {
  return useOutletContext<ContextType>();
}
