import React from 'react';
import clsx from 'clsx';

interface IListHeaderProps {
  title: string;
  actionButton?: React.ReactNode;
  className?: string;
}

const ListHeader = ({
  title,
  actionButton = undefined,
  className = '',
}: IListHeaderProps) => {
  return (
    <div
      className={clsx(
        'flex justify-between items-center px-2 py-3 sm:px-4 text-xl rounded-lg md:rounded-xl bg-gray-200 font-medium text-black',
        className
      )}
    >
      <p>{title}</p>
      {actionButton}
    </div>
  );
};

export default ListHeader;
