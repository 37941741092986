import { supabase } from '@shared/supabase';
import { MutationProvider, SupabaseError } from '@shared/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { removePrescription } from './remove-prescription';

export const useRemovePrescriptionMutation: MutationProvider<
    { status: string },
    number,
    SupabaseError
> = (options) => {
    const queryClient = useQueryClient();
    return useMutation(
        ['remove-prescription'],
        async (prescriptionId) => {
            const response = await removePrescription(supabase, prescriptionId);
            await queryClient.invalidateQueries({ queryKey: ['medicine-intakes'] });
            await queryClient.invalidateQueries({ queryKey: ['prescriptions'] });
            return response;
        },
        options
    );
};
