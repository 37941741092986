import { serverErrorParser } from '@shared/supabase';
import { Client, InsertMessagesRequest } from '../model/types';

export async function insertMessages(
  client: Client,
  payload: InsertMessagesRequest
) {
  const { data, error } = await client.from('messages').insert(payload);

  if (error) {
    return await serverErrorParser(error);
  }
  return data;
}
