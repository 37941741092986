import { toast } from 'react-toastify';
import { useRemovePrescriptionMedicineMutation } from '../api/useRemovePrescriptionMedicine';
import RemoveMedicineButton from './remove-medicine-button';
import { useToggle } from 'react-use';

type RemoveMedicineButtonProps = {
  prescriptionMedicineId: number;
  prescriptionId: number;
};

const RemovePrescriptionMedicineButton = ({
  prescriptionMedicineId,
  prescriptionId,
}: RemoveMedicineButtonProps) => {
  const [showRemoveWarningModal, toggleRemoveWarningModal] = useToggle(false);
  const { mutate: removePrescriptionMedicine, isLoading } =
    useRemovePrescriptionMedicineMutation();

  const handleRemoveMedicine = () => {
    if (prescriptionMedicineId) {
      removePrescriptionMedicine(
        { medicineId: prescriptionMedicineId, prescriptionId },
        {
          onSuccess: () => {
            toast.success('Вы успешно удалили лекарство');
          },
          onError: (error) => {
            toast.error(error);
          },
        }
      );
    }
  };

  return (
    <RemoveMedicineButton
      handleRemoveMedicine={handleRemoveMedicine}
      toggleModal={toggleRemoveWarningModal}
      showModal={showRemoveWarningModal}
      isLoading={isLoading}
    />
  );
};

export default RemovePrescriptionMedicineButton;
