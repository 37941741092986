import React from 'react';
import { Combobox } from '@headlessui/react';
import { Image } from '@shared/ui/index';
import { usePatientOnlineStatus } from '@shared/hooks/usePatientOnlineStatus';
import cn from 'src/shared/utils/cn';

interface Person {
  id: string;
  name: string;
  imageUrl: string;
}

interface Props {
  person: Person;
}

const ChatPatientsPerson = ({ person }: Props) => {
  const showOnline = usePatientOnlineStatus(person.id);

  return (
    <Combobox.Option
      as="div"
      value={person}
      className="flex border-b border-gray-200 cursor-pointer hover:bg-gray-100 select-none items-center rounded-0 p-2"
    >
      <>
        <div className="relative">
          <span
            className={cn(
              showOnline ? 'bg-green-600' : 'bg-red-600',
              'absolute w-[15px] h-[15px] rounded-full border-2 border-white left-[38px] bottom-[8px]'
            )}
          />
        </div>
        <Image
          url={person.imageUrl}
          alt=""
          className="h-12 w-12 flex-none rounded-full bg-slate-600"
        />
        <span className="ml-3 flex-auto truncate">{person.name}</span>
      </>
    </Combobox.Option>
  );
};

export default ChatPatientsPerson;
