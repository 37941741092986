import { z } from 'zod';
import { TFunction } from 'react-i18next';

export const signInFormSchema = (t: TFunction<'translation', undefined>) =>
  z.object({
    phone: z
      .string()
      .nonempty(t('This field is required.'))
      .regex(
        /^(\+7|7|8)?[\s\-]?\(?[9][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/,
        t('Invalid phone number')
      ),
  });
